import React, { useMemo } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { CustomArticleWidget } from "./CustomArticleWidget";
import {
  useUserId,
  useCountryId,
  useStoreId,
} from "../../../core/auth/useLoggedInUser";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { CreateTestBuy, TestBuyProduct } from "@coworker/types";
import dayjs from "dayjs";
import { ARTICLE_HASH } from "../constants";
import { CustomTestBuyArticleCard } from "../CommonComponents/ArticleDetailsCard/CustomTestBuyArticleCard";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import Confirmation from "../../InputPopup/ConfirmationPrompt";
import StaticFilterRow from "../TestBuyDetailPage/StaticFilterRow";
import { Button, useToastNotification } from "@coworker/components";
import { WARNING_TOAST } from "@coworker/components/src/components/ToastNotification";
import { postToTestbuyService } from "../../../hooks/API/testbuy.service";
import { useMyStore } from "../../../hooks/useMyStore";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";

const MaxNumberOfArticles = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const FilterWrapper = styled.div`
  width: 100%;
`;

const NumberOfArticlesHeader = styled.h3`
  padding: 1rem 0;
  border-bottom: 1px solid var(--grey150);
  width: 100%;
  font-size: 18px;
`;

const ArticlesLengthWrapper = styled.div`
  margin-left: 2rem;
`;

export const CustomTestBuyForm = () => {
  const [articles, setArticles] = React.useState<TestBuyProduct[]>([]);
  const uid = useUserId();
  const storeId: string = useStoreId();
  const { push, pop } = useWorkspacesAction();
  const [popup, setPopup] = React.useState<boolean>(false);
  const { showToast } = useToastNotification();
  const countryId = useCountryId();
  const { t } = useTranslation();
  const settingsPrefix = profilePreferences.TESTBUY_PREFIX;
  const filter = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS,
    ""
  )[0];

  const area = filter[1];

  const store = useMyStore();
  const storeConfiguration = store?.configuration || {};
  const storeLocale = storeConfiguration?.locale || {};
  const [userLanguage] = useUserPreference(
    profilePreferences.LANGUAGE,
    storeConfiguration?.locale?.primary_locale
  );
  const userOrStoreLanguage = userLanguage || storeLocale;
  const onSubmit = async () => {
    const createTestbuyBody = {
      numberOfArticles: articles?.length,
      productIds: articles?.map((article) => article.shortId),
      area,
      storeId,
      includeOutgoing: true,
      includeOutOfStock: true,
      createdAt: dayjs().format("YYYY-MM-DD"),
      createdBy: uid,
      typeOfArticles: ARTICLE_HASH.CUSTOM?.type,
      divisionId: "",
      area_new: [area],
      countryId,
      locale: userOrStoreLanguage,
    };
    try {
      if (!area) return;
      await postToTestbuyService(
        "testbuy/create_testbuy",
        { ...createTestbuyBody, countryId, locale: userOrStoreLanguage },
        (data: CreateTestBuy) => {
          if (data.testBuyId) {
            push(`/testbuy/${data.testBuyId}`);
          } else {
            const message =
              data?.message === "INSUFFICIENT_ARTICLES" ? (
                <Trans>insufficientArticles</Trans>
              ) : (
                <Trans>searchErrorString</Trans>
              );
            showToast(message, undefined, undefined, 5, WARNING_TOAST);
          }
        }
      );
    } catch (error) {
      const message =
        error instanceof Error && error.message === "INSUFFICIENT_ARTICLES" ? (
          <Trans>insufficientArticles</Trans>
        ) : (
          <Trans>searchErrorString</Trans>
        );
      showToast(message, undefined, undefined, 5, WARNING_TOAST);
    }
  };

  const linkbarItems = useMemo(() => {
    return [
      {
        label: t("testBuysString"),
        to: "/testbuy",
      },
      {
        label: t("newTestBuy"),
        to: "/testbuy/new",
      },
    ];
  }, [t]);

  return (
    <FullScreenPopup
      noPadding
      actionBarContent={
        <Button
          primary
          dark
          text={<Trans>createTestbuyString</Trans>}
          disabled={!articles.length}
          onClick={onSubmit}
        />
      }
      linkbar={
        <Linkbar
          items={linkbarItems}
          currentLabel={t("newCustomTestBuy")}
          currentAsH1
        />
      }
    >
      <FilterWrapper>
        <StaticFilterRow
          typeOfArticles={ARTICLE_HASH.CUSTOM?.type}
          area={area}
        />
      </FilterWrapper>
      <NumberOfArticlesHeader>
        <ArticlesLengthWrapper>
          <Trans>articlesAddedString</Trans>: <span>{articles.length}</span>
        </ArticlesLengthWrapper>
      </NumberOfArticlesHeader>
      {articles?.map((article) => (
        <CustomTestBuyArticleCard article={article} key={article.shortId} />
      ))}
      {articles?.length < 30 ? (
        <CustomArticleWidget articles={articles} setArticles={setArticles} />
      ) : (
        <MaxNumberOfArticles>
          <Trans>youCantAddMoreArticlesString</Trans>
        </MaxNumberOfArticles>
      )}
      {popup && (
        <Confirmation
          onSubmit={() => {
            pop();
          }}
          onCancel={() => {
            setPopup(false);
          }}
          onClose={() => {
            setPopup(false);
          }}
          swapButtons={false}
          question={<Trans>testbuyWillBeDeletedIfGoBackString</Trans>}
          description={<Trans>youWillLoseAllDataSoFarString</Trans>}
          negativeText={<Trans>cancelString</Trans>}
          positiveText={<Trans>tbOkay</Trans>}
        />
      )}
    </FullScreenPopup>
  );
};
