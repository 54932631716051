import { FilterOptionsKeys } from "../types/filterOptionsTypes";

type AppliedPillsType = {
  transKey: string;
  id: FilterOptionsKeys;
};

export const appliedPills: AppliedPillsType[] = [
  {
    transKey: "assignedToTaskFilterString",
    id: "assignedTo",
  },
  {
    transKey: "createdByString",
    id: "createdBy",
  },
  {
    transKey: "task_typeString",
    id: "taskType",
  },
  {
    transKey: "sortedByString",
    id: "sortedBy",
  },
  {
    transKey: "location3String",
    id: "locations",
  },
  {
    transKey: "pickupLocation",
    id: "pickupLocations",
  },
  {
    transKey: "refillLocString",
    id: "refillLocations",
  },
  {
    transKey: "priorityTasksOnlyString",
    id: "priority",
  },
  {
    transKey: "periodString",
    id: "period",
  },
];

type DropdownPillsType = {
  transKey: string;
  id: Extract<
    FilterOptionsKeys,
    | "assignedTo"
    | "createdBy"
    | "taskType"
    | "sortedBy"
    | "locations"
    | "period"
  >;
};

export const dropdownPills: DropdownPillsType[] = [
  {
    transKey: "task_typeString",
    id: "taskType",
  },
  {
    transKey: "periodString",
    id: "period",
  },
  {
    transKey: "sortedByString",
    id: "sortedBy",
  },
];

type CheckboxPillsType = {
  transKey: string;
  id: Partial<FilterOptionsKeys>;
};

export const checkBoxPills: CheckboxPillsType[] = [
  {
    transKey: "myString",
    id: "assignedTo",
  },
  {
    transKey: "priorityTasksOnlyString",
    id: "priority",
  },
];
