import React from "react";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";
import useCallInternal, {
  GET_DIVISIONS,
} from "../../../../hooks/API/useCallInternal";
import { SkapaTabs } from "../../SkapaTabs2";
import { DivisionTab, DivisionTabPanel } from "./DivisionTabPanel";
import { divisionTabData } from "../../../TestBuy/constants";
import { Division } from "@coworker/types";
import { showTab } from "./divisions.helpers";
import {
  getAreaFilter,
  getDivisionFilter,
  defaultFilters,
} from "../filterChoices";
import { useUserPreference } from "../../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";

interface DivisionComponentProps {
  storeId: string;
  setChecked: Function;
}

export function DivisionComponent({
  storeId,
  setChecked,
}: DivisionComponentProps) {
  let divisions: Division[] = [];
  const { data: res, loading } = useCallInternal(GET_DIVISIONS, {
    storeId: storeId,
  });
  if (loading === false) {
    divisions = res;
  }

  const [filters] = useUserPreference(
    profilePreferences.TESTBUY_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );

  const { pathname } = useFixaLocation();

  const [currentCheckedButton, setCurrentCheckedButton] = React.useState({
    area:
      pathname !== "/testbuy/new"
        ? getAreaFilter(filters) ?? "whole=whole"
        : "",
    div:
      pathname !== "/testbuy/new" ? getDivisionFilter(filters) ?? "SR" : "SR",
  });

  const isWholeStore = (key: string) => key === "wholestore";

  const myTab = divisionTabData.map(({ key, title, hash = {} }) => {
    const { divisionShortName } = hash;
    const hasDivisionId = isWholeStore(key)
      ? true
      : showTab(divisionShortName, divisions);

    return (
      <DivisionTab
        key={key}
        hasDivisionId={hasDivisionId}
        title={title}
        tabKey={divisionShortName}
        onTabChanged={setCurrentCheckedButton}
        setChecked={setChecked}
      />
    );
  });

  const myPanels = divisionTabData.map(({ key, hash = {} }) => {
    const { divisionShortName } = hash;
    const filteredDivisions = isWholeStore(key)
      ? divisions
      : divisions?.filter(
          (div: Division) => div.division_id === divisionShortName
        );

    return (
      <DivisionTabPanel
        key={key}
        currentCheckedButton={currentCheckedButton.area}
        divisions={filteredDivisions}
        tabKey={divisionShortName}
        setCurrent={setCurrentCheckedButton}
        setChecked={setChecked}
        isWholeStore={isWholeStore(key)}
      />
    );
  });

  return (
    <SkapaTabs
      tabs={myTab}
      active={currentCheckedButton.div}
      panels={myPanels}
    />
  );
}
