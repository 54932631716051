import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { formatProductIdWithDots } from "@coworker/reusable";
import { issueTypeDescriptions } from "../constants/issueTypeDescriptions";
import { describeTheProblemList } from "../constants/describeTheProblemList";
import { getTranslationKeyFromReason } from "@coworker/app/src/components/RoomSettings/tools";
import { Task } from "@coworker/types/lib/tasks/base";
import { AddonTask } from "@coworker/types/lib/tasks/addon";
import { ProductQualityTask } from "@coworker/types/lib/tasks/product_quality";
import { ProductIssueTask } from "@coworker/types/lib/tasks/product_issue";
import { MFAQFollowUp } from "@coworker/types/lib/tasks/mfaq_followup";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { CustomTask } from "@coworker/types/lib/tasks/custom";
import { Item } from "../../shared/types/item";
import { getClosedTaskReason } from "./getClosedTaskReason";

export type BannerInformation = {
  closeType?: string;
  requestedQuantity?: string;
  location?: string;
  pickupLocation?: string;
  failedCriteria?: string;
} | null;

export const getTaskInfo = (
  task: Task,
  t: (key?: string) => string,
  productUnitTitle: string,
  itemInfo: Item | undefined
) => {
  let type: string = "",
    title: string = "",
    subtitles: string[] = [],
    bannerInformation: BannerInformation = {};

  switch (task.task_type) {
    case taskTypeOptions.ADDON:
      const addonTask = task as AddonTask;
      const isItemNameMissing =
        !addonTask?.item_name ||
        addonTask?.item_name === "MISSING_PRODUCT_NAME";
      const itemName = isItemNameMissing ? itemInfo?.name : addonTask.item_name;
      type =
        addonTask.task_type_detail === "REFILL" || addonTask.picked
          ? t("refillString")
          : t("addonTitleString");
      title = itemName ?? "";
      subtitles = [
        formatProductIdWithDots(addonTask.product_article_id),
        itemInfo?.description ?? "",
      ];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        requestedQuantity: `${addonTask.actual_requested_quantity} ${productUnitTitle}`,
        pickupLocation: addonTask.pickup_location_custom
          ? addonTask.pickup_location_custom
          : addonTask.pickup_location_grid_code,
        location: addonTask.location_custom
          ? addonTask.location_custom
          : addonTask.location_grid_code,
      };
      break;

    case taskTypeOptions.PRODUCT_QUALITY:
      const productQualityTask = task as ProductQualityTask;
      type = t("productQualityReportString");
      title = t(
        describeTheProblemList.find(
          (problem) =>
            problem.type === productQualityTask.pq_describe_the_problem_type
        )?.transKey
      );
      subtitles = [
        productQualityTask.item_name,
        formatProductIdWithDots(productQualityTask.product_article_id),
      ];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;
      break;

    case taskTypeOptions.PRODUCT_ISSUE:
      const productIssueTask = task as ProductIssueTask;
      type = t("taskTypeTitleProductIssue");
      title = t(
        issueTypeDescriptions.find(
          (issue) => issue.type === productIssueTask.issue_type
        )?.transKey
      );
      subtitles = [
        productIssueTask.item_name,
        formatProductIdWithDots(productIssueTask.product_article_id),
      ];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;

      break;

    case taskTypeOptions.MFAQ_FOLLOW_UP:
      const mfaqTask = task as MFAQFollowUp;
      type = t("mfaqFollowUpTitle");
      title = mfaqTask.question_text;
      subtitles = [mfaqTask.description ?? ""];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        location: mfaqTask.question_area?.includes("depts")
          ? `HFB ${mfaqTask.question_area?.split("=")[1]}`
          : mfaqTask.question_area?.includes("whole")
          ? `${t("wholeStoreString")}`
          : `${mfaqTask.question_area?.split("=")[1]}`,
      };
      break;

    case taskTypeOptions.TESTBUY_FOLLOW_UP:
      const testbuyTask = task as TestBuyFollowUp;
      type = t("testBuyFollowUpString");
      title = testbuyTask.item_name;
      subtitles = [
        formatProductIdWithDots(testbuyTask.product_article_id),
        itemInfo?.description ?? "",
      ];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        location: testbuyTask.location ?? "",
        failedCriteria: `• ${
          testbuyTask?.failedCriteria?.length ||
          testbuyTask?.failed_criteria?.length
        } ${t("criteraFailedString")}`,
      };
      break;

    case taskTypeOptions.ROOM_SETTINGS:
      const roomSettingsTask = task as RoomSettings;
      type = t("roomSettingsString");
      title = t(getTranslationKeyFromReason(roomSettingsTask.reason));
      subtitles = [
        roomSettingsTask.item_name,
        formatProductIdWithDots(roomSettingsTask.product_article_id),
      ];
      bannerInformation = {
        closeType:
          (task.close_type && t(getClosedTaskReason(task.close_type))) || "",
        location: roomSettingsTask.room_name,
      };
      break;

    case taskTypeOptions.CUSTOM:
      const customTask = task as CustomTask;
      const isScheduledTask =
        customTask.planned || customTask.repeatable_task_id;
      type = isScheduledTask ? t("scheduledTaskString") : t("customString");
      title = customTask.custom_title;
      subtitles = [customTask.description ?? ""];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;
      break;

    case taskTypeOptions.PLANNED:
      const plannedTask = task as CustomTask;
      type = t("mainScheduledTaskString");
      title = plannedTask.custom_title;
      subtitles = [plannedTask.description ?? ""];
      bannerInformation = task.close_type
        ? {
            closeType: t(getClosedTaskReason(task.close_type)),
          }
        : null;
      break;
  }

  return { type, title, subtitles, bannerInformation };
};
