import React from "react";
import * as Sentry from "@sentry/react";
import { CaptureContext, SeverityLevel } from "@sentry/types";
import {
  useCountryId,
  useFixaUID,
  useStoreId,
} from "../core/auth/useLoggedInUser";
import { useUserPreference } from "./useProfilePreferencesQuery";
import preferences from "@coworker/functions/src/enums/profilePreferences.json";

export function useConfigureSentry() {
  const storeId = useStoreId();
  const countryId = useCountryId();
  const fixaUid = useFixaUID();
  const [language] = useUserPreference(preferences.LANGUAGE, "");
  React.useEffect(() => {}, [language, storeId, countryId, fixaUid]);
}

/**
 * Reports a message to sentry. Does not require an error.
 * @param message Message to report
 * @param level severity level of the message, defaults to info
 * @param extras extra data to send to sentry
 * @example reportMessageToSentry("Something bad is happening, but we can recover", null, "warning");
 * @example reportMessageToSentry("We did a thing!", { someKey: "someValue" }, "log");
 * @example reportMessageToSentry("This is a sample message");
 */
export async function reportMessageToSentry(
  message: string,
  extras: Omit<CaptureContext, "level">,
  level: SeverityLevel = "info"
) {
  Sentry.captureMessage(message, { level, extra: { ...extras } });
  return Sentry.flush(2000);
}
