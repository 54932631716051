import React from "react";
import { useToastNotification } from "@coworker/components";
import useUndoneNotification from "../../components/useUndoneNotification";
import { updateTask } from "../../services/task.service";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import trackerHelper from "../../helpers/tracker";
import { Trans } from "@coworker/locales";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { prepareToCelebrateWithAnAnimation } from "../../helpers/taskFilters";
import { useTaskRefresher } from "../useWatchedTasks";
import { updateQuestionTask } from "../../components/MFAQ/BackendApiRepository/MfaqRepository";

export const useUndoCompleteTask = ({ task, afterUndo }) => {
  const undoCurtain = useUndoneNotification();
  const uid = useUserId();
  const refreshTask = useTaskRefresher();

  const undoTask = React.useCallback(
    async (actionId) => {
      let data = {
        last_editor_id: uid,
        assigned_user_id: uid,
        completed_note: "",
        state: taskStateOptions.ASSIGNED,
        undo_action_id: actionId || "",
        activity_type: activityTypes.UNDO_COMPLETE,
      };
      if (task.task_type === taskTypeOptions.ADDON) {
        data = {
          ...data,
          refilled_quantity: null, // changed to null from "" to make it compatible with tasks service
          refilled_type: "",
          actual_refilled_quantity: null, // changed to null from "" to make it compatible with tasks service
          actual_pick_quantity: task.actual_pick_quantity,
          pick_quantity: task.pick_quantity,
          pick_type: task.pick_type,
          task_type_detail: task.picked ? "REFILL" : "PICK_AND_REFILL",
          searchable_location:
            task.pickup_location_custom ?? task.pickup_location,
        };
      }
      if (task.task_type === taskTypeOptions.MFAQ_FOLLOW_UP) {
        data = {
          ...data,
          finished_summary: "",
        };
      }

      await undoCurtain(data, task.id);
      afterUndo();
      refreshTask(task.id, data, task);
    },
    [afterUndo, task, uid, undoCurtain, refreshTask]
  );

  return {
    call: undoTask,
  };
};

export const useAcceptPQReport = (task, taskId) => {
  const { showToast } = useToastNotification();
  const uid = useUserId();
  const refreshTask = useTaskRefresher();
  const storeId = useStoreId();
  return React.useCallback(
    async (completed_note) => {
      let updateData = {
        last_editor_id: uid,
        state: taskStateOptions.COMPLETED,
        action_id: `complete-${new Date().getTime()}`,
        activity_type: activityTypes.COMPLETE,
        completed_note,
        // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend
        // change has not propagated to the frontend we must make sure to also signal this from
        // the frontend!
        undo_action_id: "",
      };

      if (task.task_type === taskTypeOptions.ADDON) {
        updateData = {
          ...updateData,
          refilled_quantity: task.pick_quantity || task.requested_quantity,
          refilled_type: task.pick_type || task.requested_type,
          pick_quantity: task.pick_quantity || task.requested_quantity,
          actual_refilled_quantity:
            task.actual_pick_quantity || task.actual_requested_quantity,
          actual_pick_quantity:
            task.actual_pick_quantity || task.actual_requested_quantity,
        };
      }

      const result = await updateTask(
        updateData,
        taskId,
        task.task_type,
        storeId
      );

      refreshTask(taskId, updateData, task);
      const resultId = result?.result?.id;
      if (resultId === taskId) {
        if (updateData.task_type === taskTypeOptions.ADDON) {
          trackerHelper.trackRefillAddonTask({ ...task, task_id: taskId });
        }
        trackerHelper.trackCompleteTask(
          { ...task, task_id: taskId },
          { with_changes: false }
        );
        showToast(<Trans>youAcceptedTheTask</Trans>);
        return true;
      } else {
        showToast(<Trans>searchErrorString</Trans>); // "Oops! Something went wrong." -- most likely only showing up if device is offline but didn't yet switch to the offline view in Fixa.
        return false;
      }
    },
    [refreshTask, showToast, uid, taskId, task, storeId]
  );
};

export const useCompleteTask = (task, taskId, config = {}) => {
  const {
    undo,
    afterUndo = () => {},
    notificationTranslationKey = "completed2String",
  } = config;
  const storeId = useStoreId();
  const uid = useUserId();
  const { showToastWithUndo } = useToastNotification();
  const { call: undoTask } = useUndoCompleteTask({
    task: { ...task, id: taskId },
    afterUndo,
  });
  const refreshTask = useTaskRefresher();

  const completeTask = React.useCallback(
    async (additionaUpdates = {}) => {
      const actionId = `complete-${new Date().getTime()}`;

      let data = {
        last_editor_id: uid,
        state: taskStateOptions.COMPLETED,
        action_id: actionId,
        activity_type: activityTypes.COMPLETE,
        ...additionaUpdates,
        // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
        undo_action_id: "",
      };

      if (task.task_type === taskTypeOptions.ADDON) {
        data = {
          ...data,
          refilled_quantity: task.pick_quantity || task.requested_quantity,
          refilled_type: task.pick_type || task.requested_type,
          pick_quantity: task.pick_quantity || task.requested_quantity,
          actual_refilled_quantity:
            task.actual_pick_quantity || task.actual_requested_quantity,
          actual_pick_quantity:
            task.actual_pick_quantity || task.actual_requested_quantity,
          task_type_detail: "REFILL",
          searchable_location: task.location,
        };
      }

      if (window.callMFAQService && task.task_type === taskTypeOptions.MFAQ) {
        updateQuestionTask(
          task.question_id,
          task.id,
          task.finished_summary,
          data.state
        );
      }

      prepareToCelebrateWithAnAnimation();
      await updateTask(data, taskId, "", storeId);
      refreshTask(taskId, data, task);

      if (data.task_type === taskTypeOptions.ADDON) {
        trackerHelper.trackRefillAddonTask({ ...task, task_id: taskId });
      }
      trackerHelper.trackCompleteTask(
        { ...task, task_id: taskId },
        { with_changes: false }
      );

      if (!undo) return;

      if (task.task_type !== taskTypeOptions.MFAQ_FOLLOW_UP) {
        showToastWithUndo(<Trans>{notificationTranslationKey}</Trans>, () =>
          undoTask(actionId)
        );
      }

      return true;
    },
    [
      refreshTask,
      undoTask,
      showToastWithUndo,
      uid,
      taskId,
      task,
      undo,
      notificationTranslationKey,
      storeId,
    ]
  );

  return {
    call: completeTask,
  };
};
