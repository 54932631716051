import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ImageScroller } from "../../core/components/ImageScroller";
import { ProductShowcase } from "./ProductShowcase";
import { Trans } from "@coworker/locales";
import { parseProduct } from "../../services/products.service";
import { useAllSalesLocationsForArticleQuery } from "../../services/locations.service";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { useAvailableElevatedStock } from "../../hooks/useAvailableElevatedStock"; // TODO: Change to use from tasks-service only!
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import trackerHelper from "../../helpers/tracker";
import { useProductQualityTeamId } from "../../hooks/useProductQualityTeamId";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { isRDTDevice, generateLivligProductLink } from "@coworker/reusable";
import { WidgetRow } from "../../core/components/PaddedRow";
import { useExpectedDelivery } from "../../hooks/useExpectedDelivery"; // NOTE: This is a direct call to the CIA API. No urgent need to pass this through backend.
import { DeliveryContainer } from "@coworker/components";
import { formatProductIdWithDots } from "@coworker/reusable";
import featureNames from "@coworker/functions/src/enums/featureNames.json";
import tracker from "../../helpers/tracker";
import { StockIndicator } from "../stock";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { TopBarOverflowButton } from "../../core/components/TopBarComponents";
import { ArticleError } from "@coworker/apprestructured/src/shared/components/ArticleError/ArticleError";
import { useInputPopup } from "../../components/InputPopup";
import { usePIPHome } from "@coworker/apprestructured/src/shared/hooks/item/usePIPHome";
import Linkbar from "@coworker/apprestructured/src/shared/components/Linkbar/Linkbar";
// This appears here and there. It's usually 90px high.
const NavBarSpacer = styled.div`
  height: 84px;
`;

const WidgetLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
`;

const LocationInfo = styled.div`
  font-weight: normal;
`;

const StockIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PIPWidgetRow = styled(WidgetRow)`
  padding: 19px 28px;
`;

function getStockTitle(stockAvailable) {
  if (stockAvailable === "") {
    return <Trans>noStockInfoString</Trans>;
  }
  return [0, "0"].includes(stockAvailable) ? (
    <Trans>outOfStockTitle</Trans>
  ) : (
    <Trans>inStockTitle</Trans>
  );
}

function getLocationInfo(location, locationDescription) {
  if (location && locationDescription)
    return `${location} • ${locationDescription}`;
  else if (location) return `${location}`;
  else return "";
}

// - It is important to have 'livligLinkClicked' as a global variable. Purpose of 'livligLinkClicked'
// is to keep track on if the Livlig Link has been klicked or not. If not, then the trackLinkDisplay
// should be tracked. See Task:
// https://jira.digital.ingka.com/browse/COAPP-5311
// - The trackLinkDisplay is done in the unmounting (the return function of useEffect) of the component
// i.e. when the component stops being shown.
// - If 'livligLinkClicked' is moved into the component, then it will be problematic as the component is rerendered
// several time during the drawing process thus, generating a lot of logs,
let livligLinkClicked = false;

const PIPHomeViewInner = ({
  productData,
  short_id,
  type,
  isBreathTakingItem,
  homeBaseLocation,
  homeBaseDescription,
}) => {
  const { push } = useWorkspacesAction();
  const full_id = type + short_id;
  const { available, color } = useAvailableElevatedStock(full_id);
  const { formattedDelivery } = useExpectedDelivery(short_id);
  const [, setDepartment] = useUserPreference(
    profilePreferences.INSIGHTS_FILTER_HFB,
    "ALL"
  );
  const store_id = useStoreId();
  const isRDT = isRDTDevice();

  const trackLinkDisplaySentIfLivligLinkHasNotBeenClicked = (
    _store_id,
    _short_id
  ) => {
    if (!livligLinkClicked) {
      tracker.livlig.trackLinkDisplay(_store_id, _short_id, featureNames.PIP);
    }
    // As 'livligLinkClicked' is global, it has to be reset.
    livligLinkClicked = false;
  };

  React.useEffect(() => {
    return () => {
      trackLinkDisplaySentIfLivligLinkHasNotBeenClicked(store_id, short_id);
    };
  }, [store_id, short_id]); // Array so that the unmount is only called once.

  return (
    <React.Fragment>
      <ProductShowcase
        name={productData?.name}
        price={productData?.salesPrice?.price}
        currency={productData?.salesPrice?.currency}
        priceUnit={productData?.salesPrice?.unit}
        originalPrice={productData?.salesPrice?.previousPrice?.price}
        isBreathTakingItem={isBreathTakingItem}
        isFamilyPrice={productData?.salesPrice?.isFamilyPrice}
        newLowerPrice={productData?.salesPrice?.newLowerPrice}
        description={productData.description || ""}
        formattedNumber={formatProductIdWithDots(productData.no)}
      />
      <PIPWidgetRow
        className="PIPView"
        onClick={() =>
          push(`/product/${full_id}/stock`, { title: productData.name })
        }
        middle={
          <WidgetLabel>
            <Trans>{getStockTitle(available)}</Trans>
            {!["", 0].includes(available) && (
              <LocationInfo>
                {getLocationInfo(homeBaseLocation, homeBaseDescription)}
              </LocationInfo>
            )}
            {available === 0 && formattedDelivery && (
              <DeliveryContainer>
                <Trans>expectedDelivery</Trans>: {formattedDelivery}
              </DeliveryContainer>
            )}
          </WidgetLabel>
        }
        right={
          <StockIndicatorWrapper>
            <StockIndicator size={8} marginRight={12} color={color} />
          </StockIndicatorWrapper>
        }
        testId="stock"
      />
      <PIPWidgetRow
        className="PIPView"
        onClick={() => {
          setDepartment("ALL");
          trackerHelper.trackPIPToInsights(full_id);
          push(`/insights/product/${full_id}`);
        }}
        middle={
          <WidgetLabel>
            <Trans>addonInsightsString</Trans>
          </WidgetLabel>
        }
        testId="insights"
      />
      {!isRDT && (
        <PIPWidgetRow
          className="PIPView"
          onClick={() => {
            livligLinkClicked = true;
            tracker.livlig.trackLinkClick(store_id, short_id, featureNames.PIP);
            window.open(
              generateLivligProductLink(store_id, short_id),
              "_blank"
            );
          }}
          middle={
            <WidgetLabel>
              <Trans>livligLinkWidgetTitle</Trans>
            </WidgetLabel>
          }
        />
      )}
      <NavBarSpacer />
    </React.Fragment>
  );
};

export function PIPHomeView() {
  const { full_id: maybeFullId } = useParams();
  const { push } = useWorkspacesAction();
  const [shortId, type] = parseProduct(maybeFullId);
  const { data: pipHomeData, isLoading } = usePIPHome(shortId);
  const isPriceOrCurrencyMissing =
    !pipHomeData?.salesPrice?.price || !pipHomeData?.salesPrice?.currency;

  const { data: locationsData, isLoading: isLoadingLocations } =
    useAllSalesLocationsForArticleQuery(
      !!type && !!shortId ? `${type}${shortId}` : ""
    );
  const { getInput, closePopup } = useInputPopup();

  const [showArticleErrorModal, setShowArticleErrorModal] =
    React.useState(false);
  const onCloseArticleErrorModal = () => {
    setShowArticleErrorModal(!showArticleErrorModal);
  };

  const {
    location: homeBaseLocation,
    locationDescription: homeBaseDescription,
    descriptive: homeDescriptive,
  } = (!isLoadingLocations &&
    locationsData?.find((l) => l.isHomeBase || l.isHome)) ||
  {};

  const hasActiveProductQualityTeam = !!useProductQualityTeamId();
  const productQualityTeamId = useProductQualityTeamId();

  const onAction = (which, isPriceOrCurrencyMissing = false) => {
    if (which) {
      if (which === "addon" && isPriceOrCurrencyMissing) {
        closePopup();
        setShowArticleErrorModal(true);
      } else {
        closePopup();
        push(`/task/new/${which}`, {
          product: { product: shortId, type },
          qualityTeamId: productQualityTeamId,
        });
      }
    }
  };

  const actions = [
    {
      title: (
        <b>
          <Trans>createProdTask</Trans>
        </b>
      ),
    },
    {
      title: <Trans>productString</Trans>,
      onClick: () => onAction("product"),
    },
    {
      title: <Trans>addonOrderString</Trans>,
      onClick: () => onAction("addon", isPriceOrCurrencyMissing),
    },
  ];

  if (hasActiveProductQualityTeam) {
    actions.push({
      title: <Trans>productQualityReportString</Trans>,
      onClick: () => onAction("product_quality"),
    });
  }

  React.useEffect(() => {
    trackerHelper.trackPIPEntered();
  }, []);

  const { images, bigImage } = pipHomeData?.productImages || {};
  const chosenImages = React.useMemo(
    // When images is empty we try to take bigImage
    () => (images?.length ? images : [bigImage]),
    [images, bigImage]
  );

  const productData = React.useMemo(
    () =>
      !isLoading && pipHomeData
        ? {
            name: pipHomeData.name,
            description: pipHomeData.description,
            measurements: pipHomeData.measurements,
            no: pipHomeData.no,
            salesPrice: pipHomeData.salesPrice,
            isFamilyPrice: pipHomeData?.salesPrice?.isFamilyPrice,
            newLowerPrice: pipHomeData?.salesPrice?.newLowerPrice,
            location: pipHomeData.location || "?",
            locationDescription: pipHomeData.locationDescription || "?",
          }
        : {},
    [isLoading, pipHomeData]
  );

  // NOTE: We removed the gpr switcher, which would otherwise allow switching between similar products - like white or transparent version of https://www.ikea.com/it/it/p/variera-rivestimento-per-cassetto-bianco-00238865/#content vs https://www.ikea.com/it/it/p/variera-rivestimento-per-cassetto-trasparente-80012853/#content
  return (
    <FullScreenPopup
      noPadding
      testId="pip-home-view"
      appBarConfig={{
        title: productData.name,
        actions: [
          {
            name: "Menu",
            icon: (
              <TopBarOverflowButton actions={actions} getInput={getInput} />
            ),
            onClick: () => {},
            position: "right",
          },
        ],
      }}
      linkbar={
        <Linkbar currentLabel={productData.name} loading={!productData.name} />
      }
    >
      {(isLoading || isLoadingLocations) && <PositionedLoaderIcon />}
      {!isLoading && !isLoadingLocations && (
        <>
          <ImageScroller
            images={chosenImages}
            ofString={<Trans>ofString</Trans>}
          />
          <PIPHomeViewInner
            productData={productData}
            type={type}
            short_id={shortId}
            isBreathTakingItem={pipHomeData.isBreathTakingItem}
            homeBaseLocation={homeBaseLocation}
            homeBaseDescription={homeBaseDescription || homeDescriptive}
          />
        </>
      )}
      <ArticleError
        openArticleErrorModal={showArticleErrorModal}
        onClose={onCloseArticleErrorModal}
      />
    </FullScreenPopup>
  );
}
