// Flags and their explanations
const featureFlags = {
  ACES_AND_KING:
    "Used in new testbuy flow,provides an option for users to choose articles type Randon/Aces and king",
  // Temporary, to be removed when the feature is released.
  ENABLE_PICKUP_FUNCTION:
    "Enables the new function for picking up tasks to mitigate the offline issues we are facing",
  ENABLE_CLOSE_FUNCTION:
    "Enables the new function for closing tasks to mitigate the offline issues we are facing",
  DIVISIONS_IN_FILTER:
    "Changes departments to divisions containing HFBs that become specific areas, showroom for example ",
  // In use by stores/users/countries.
  BARCODE_DEBUG: "Advanced logging for barcodes",
  BETTER_PICKUP: "Build better default pickup fields for 308 Crny Most",
  DISABLE_NEARBY: "Test disabling Nearby for US store 165.",
  DISABLE_MFS_SEARCH:
    "Do not use MFS Search on the Search screen, for store 455 (and later globally).",

  // For checking translations.
  BLABLA_LANGUAGE:
    "Replaces translations with 'blabla'. Helps finding what's not translated.",
  ALL_LOCALES:
    "Disables store-related language/country filtering when choosing language.",

  MAINTENANCE:
    "Shows a message and hides Fixa (not Admin). Hopefully won't be needed again. Use if Fixa is broken / needs to wait for DB restore.",

  FORWARD_TO_TASKS_SERVICE:
    "When creating task also create the task in the new Task Service (shadow-copy).",

  CALL_TASKS_SERVICE: "Call Tasks Service instead of Firebase",
  CALL_TESTBUY_SERVICE: "Call Testbuy Service instead of Firebase",

  CALL_TASKS_SERVICE_INSIGHTS: "Call new Tasks Service for Insights",
  ENABLE_REFILL_TYPE: "Enable selection of Refill type in Add on task.",
  ENABLE_NEW_MFAQ_SERVICE: "Enable the new services for MFAQ backend",
  CALL_MFAQ_SERVICE: "Call MFAQ Service instead of Firebase",
  USE_FIXA_UID_IN_APP: "Use fixa_uid instead of uid in app and backend",
  GERMANY_ROLLOUT:
    "Germany rollout, hides Test buy, PQR and Shopping tool and poorly assembled type in product issue",
  SPR_TESTBUY: "Allows SPR testbuy to be created in the custom testbuy flow",
  REQUIRE_PROOF_OF_COMPLETION:
    "Require 'Proof of Completion' on Room Settings Tasks before being able to Complete the Task. Only use for those who requests it.",
  ENABLE_NEW_ACTIVITY_PAGE: "Enable the new activity page",
  HIDE_EXCEL_EXPORT: "Hide excel export of insights and mfaq",
  SHOW_EDS_DATE: "Show EDS date in media maintenance articles list view",
  ENABLE_DISCOVER_NOW: "Show discover now on homepage",
};

// Export object with the names of flags. Used by Fixa + Admin.
module.exports.FLAGS = Object.keys(featureFlags).reduce(
  (all, key) => ({ ...all, [key]: key }),
  {}
);

module.exports.flagsWithDescriptions = featureFlags;
