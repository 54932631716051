import React from "react";
import styles from "./activity-overview.module.css";
import { ActivityFilters } from "./filters/ActivityFilters";
import { TaskStateTabs } from "./states/TaskStateTabs";
import { ActivityColumn } from "./column/ActvityColumn";
import { ActivityFilterType } from "../types/filterOptionsTypes";
import { DEFAULT_FILTER_VALUES } from "../constants/defaultFilterValues";
import { AppliedFilters } from "./filters/applied/AppliedFilters";
import { useActivityData } from "../hooks/useActivityData";
import { Trans } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import { useCachedTasks } from "@coworker/app/src/services/tasks.service";

export const ActivityOverview = () => {
  const [activeTab, setActiveTab] = React.useState("OPEN");
  const [filters, setFilters] = React.useState<ActivityFilterType>(
    DEFAULT_FILTER_VALUES
  );
  const [appliedFiltersCount, setAppliedFiltersCount] = React.useState(0);

  const { openTasks, inProgressTasks, completedTasks, closedTasks } =
    useActivityData(filters) || [];

  const cachedPlannedTasks = useCachedTasks("planned");

  const allTasks = [
    ...(openTasks.data?.pages.reduce(
      (acc: Task[], page) => acc.concat(page.list),
      []
    ) || []),
    ...(inProgressTasks.data?.pages.reduce(
      (acc: Task[], page) => acc.concat(page.list),
      []
    ) || []),
    ...(completedTasks.data?.pages.reduce(
      (acc: Task[], page) => acc.concat(page.list),
      []
    ) || []),
    ...(closedTasks.data?.pages.reduce(
      (acc: Task[], page) => acc.concat(page.list),
      []
    ) || []),
  ];

  React.useEffect(() => {
    const count = Object.entries(filters).reduce((acc, [key, value]) => {
      if (key === "sortedBy") {
        return acc;
      }

      if (Array.isArray(value)) {
        return acc + value.length;
      } else if (value && value !== "") {
        return acc + 1;
      }

      return acc;
    }, 0);

    setAppliedFiltersCount(count);
  }, [filters]);

  const columns = [
    {
      transTitleKey: "teamOpenString",
      tasks: openTasks,
      tab: "OPEN",
    },
    {
      transTitleKey: "inProgressString",
      tasks: inProgressTasks,
      tab: "IN_PROGRESS",
    },
    {
      transTitleKey: "completd3String",
      tasks: completedTasks,
      tab: "COMPLETED",
    },
    {
      transTitleKey: "closedFirstUppercaseString",
      tasks: closedTasks,
      tab: "CLOSED",
    },
  ];

  return (
    <div className={styles["overview-container"]}>
      <div className={styles["title"]}>
        <Trans>activityString</Trans>
      </div>
      <ActivityFilters
        filters={filters}
        setFilters={setFilters}
        appliedFiltersCount={appliedFiltersCount}
        allTasks={allTasks}
      />
      {appliedFiltersCount > 0 && (
        <AppliedFilters filters={filters} setFilters={setFilters} />
      )}
      <TaskStateTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tasksCount={{
          open: openTasks.data?.pages[0]?.filteredCount || 0,
          inProgress: inProgressTasks.data?.pages[0]?.filteredCount || 0,
          completed: completedTasks.data?.pages[0]?.filteredCount || 0,
          closed: closedTasks.data?.pages[0]?.filteredCount || 0,
        }}
      />
      <div className={styles["overview-wrapper"]}>
        {columns.map((column) => {
          return (
            <ActivityColumn
              key={column.tab}
              activeTab={activeTab}
              title={column.transTitleKey}
              tasks={
                column.tasks?.data?.pages.map((page) => page.list).flat() || []
              }
              plannedTasks={cachedPlannedTasks.list || []}
              filteredCount={column.tasks?.data?.pages[0]?.filteredCount || 0}
              totalCount={column.tasks?.data?.pages[0]?.totalCount || 0}
              loadNextPage={column.tasks?.fetchNextPage}
              tab={column.tab}
              appliedFiltersCount={appliedFiltersCount}
              setFilters={setFilters}
              isLoading={column.tasks.isLoading}
              isFetching={column.tasks.isFetching}
            />
          );
        })}
      </div>
    </div>
  );
};
