import React from "react";
import QuantityStepper from "@ingka/quantity-stepper";
import "@ingka/focus/dist/style.css";
import "@ingka/quantity-stepper/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";

export interface Props {
  small?: boolean;
  ariaDescribedBy: string;
  ariaDescribedById: string;
  ariaLabelDecrease?: string;
  ariaLabelIncrease?: string;
  ariaLabelInput?: string;
  minVal: number;
  maxVal: number;
  defaultValue?: number;
  value?: number;
  disabled?: boolean;
  onQuantityChange?: (value: number | string) => void;
  onIncrease?: () => void;
  onDecrease?: () => void;
}

export const FixaQuantityStepper: React.FC<Props> = (props: Props) => {
  return <QuantityStepper {...props} />;
};
