import { getTokenAsync } from "./getAuthToken";
import { taskServiceBaseUrl } from "@coworker/functions/src/triggers/cloud-services.helper";
import { isChineseEnvironment, getEnvironmentId } from "@coworker/reusable";
import packageJson from "../../../package.json";
import { reportMessageToSentry } from "../useConfigureSentry";
const isChina = isChineseEnvironment();

export const TASKS_SERVICE_URL = taskServiceBaseUrl(
  getEnvironmentId(),
  isChina
);

const makeHeaders = (token) => ({
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
  "X-Client-Version": packageJson.version,
});

/***
 * @returns A promise which resolves when the request is done.
 * If you care about results then use the callback.
 */
export async function perform(
  method,
  url,
  bodyData,
  callback,
  responseType,
  additionalHeaders = {}
) {
  const token = await getTokenAsync(true);
  if (!token || typeof token !== "string") {
    return;
  }
  const params = {
    method,
    headers: { ...makeHeaders(token), ...additionalHeaders },
  };
  if ("GET" !== method && bodyData) params.body = JSON.stringify(bodyData);
  let result;
  try {
    const response = await fetch(url, params);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // Handle response based on type
    if (responseType === "blob") {
      const blob = await response.blob();
      callback(blob); // Use callback with blob data
    } else {
      const text = await response.text();
      try {
        result = JSON.parse(text);
      } catch {
        console.log("perform: Can't parse", text);
      }
      if (result) callback(result);
    }
  } catch (error) {
    console.error("Fetch error:", error);
    reportMessageToSentry(
      `Fetch error: ${error.message}`,
      {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        stack: error.stack,
        url,
        params: JSON.stringify(params),
      },
      { bodyData: JSON.stringify(bodyData) }
    );
  }
}

export async function fetchAPI(
  method,
  url,
  bodyData,
  responseType,
  additionalHeaders = {}
) {
  const token = await getTokenAsync(true);
  if (!token || typeof token !== "string") {
    throw new Error("Invalid token");
  }

  const params = {
    method,
    headers: { ...makeHeaders(token), ...additionalHeaders },
  };

  if (method !== "GET" && bodyData) params.body = JSON.stringify(bodyData);

  try {
    const response = await fetch(url, params);

    if (response?.ok) {
      if (responseType === "blob") {
        const blob = await response.blob();
        return { blob, status: response.status };
      } else {
        const result = await response.json();
        return { result, status: response.status };
      }
    } else {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  } catch (error) {
    reportMessageToSentry(
      `FetchAPI error: ${error.message} - ${url} `,
      {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        stack: error.stack,
        url,
      },
      { bodyData: JSON.stringify(bodyData) }
    );
    throw error;
  }
}

export async function postToTasksService(
  path,
  bodyData,
  responseType,
  additionalHeaders = {}
) {
  const url = `${TASKS_SERVICE_URL}${path}`;
  return new Promise((resolve, reject) => {
    fetchAPI("POST", url, bodyData, responseType, additionalHeaders)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function postToTasksServiceWithTimeoutAndRetry(
  path,
  bodyData,
  responseType,
  additionalHeaders = {},
  options = {}
) {
  const {
    retries = 3, // Number of retry attempts
    timeout = 5000, // Timeout in milliseconds
  } = options;

  const url = `${TASKS_SERVICE_URL}${path}`;

  // Timeout helper function
  const fetchWithTimeout = async (
    method,
    url,
    bodyData,
    responseType,
    headers,
    timeout
  ) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(
        () => reject(new Error("Request timed out")),
        timeout
      );

      fetchAPI(method, url, bodyData, responseType, headers)
        .then((response) => {
          clearTimeout(timer);
          if (response.status === 200) {
            return response;
          }
          reject(new Error(`Received status ${response.status}`));
        })
        .then(resolve)
        .catch((error) => {
          clearTimeout(timer);
          reject(error);
        });
    });
  };

  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const result = await fetchWithTimeout(
        "POST",
        url,
        bodyData,
        responseType,
        additionalHeaders,
        timeout
      );
      return result; // Resolve if successful
    } catch (error) {
      if (attempt === retries) {
        reportMessageToSentry(
          `ERR_TASK_CREATION: Task Type: ${
            bodyData.task_type
          }. Status: ${error}, Data: ${JSON.stringify(bodyData)}`,
          {
            error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
            stack: error.stack,
            url,
            customErrorMessage: "ERROR_DOUBLE_WRITES_TASKS_PRESET_ID",
          },
          { bodyData: JSON.stringify(bodyData) }
        );
        return Promise.reject(error);
      }
    }
  }
}

export async function getFromTasksService(path, callback) {
  const url = `${TASKS_SERVICE_URL}${path}`;
  await perform("GET", url, null, callback);
}

function findV1Endpoint(method, bodyData) {
  let [which, query] = method.split("?");
  if (which.endsWith("image/remove")) which = "image/remove";
  let taskId = getQueryIdentifierValue(query, "id");
  const short_id = getQueryIdentifierValue(query, "shortId");
  const locale = getQueryIdentifierValue(query, "locale");
  const [task, section] = which.split("/");
  switch (which) {
    case "create/task":
      return [`task/create`, "POST", bodyData.taskData];
    case "update/repeatable_task":
    case "update/task":
      return [`task/${bodyData.taskId}/update`, "PUT", bodyData.updateData];
    case "task/activities":
    case "task/notes":
    case "task/images":
    case "task/all_images":
      return [`${task}/${taskId}/${section}`];
    case "note/images":
      const noteId = getQueryIdentifierValue(query, "noteId");
      return [`task/${taskId}/notes/${noteId}/images`];
    case "task/create_note":
      return [`${task}/${taskId}/${section}`, "POST", bodyData];
    case "create/images":
      taskId = bodyData.parentPath.split("/")[1];
      return [`task/${taskId}/images/create`, "POST", bodyData];
    case "image/remove":
      const imageId = getQueryIdentifierValue(query, "imageId");
      return [`task/${imageId}/detach_image`, "DELETE"];

    case "amount_settings/set":
      const pieces_per_multipack = bodyData?.amounts?.pieces_per_multi_pack;
      const pieces_per_pallet = bodyData?.amounts?.pieces_per_pallet;
      return [
        "shared/amount_settings",
        "PUT",
        {
          short_id: bodyData.shortId,
          // TODO: Will parseInt return NaN here if we have bad data from frontend / from previously stored data?
          pieces_per_multi_pack:
            pieces_per_multipack !== "" && pieces_per_multipack !== null
              ? parseInt(pieces_per_multipack)
              : null,
          pieces_per_pallet:
            pieces_per_pallet !== "" && pieces_per_pallet !== null
              ? parseInt(pieces_per_pallet)
              : null,
        },
      ];
    case "amount_settings":
      return [`shared/amount_settings?short_id=${short_id}`];

    case "my/events":
      return ["/shared/my/notifications", "GET"];
    case "my/events/clear":
      return ["/shared/my/notifications", "DELETE"];
    // case "my/events/markEventRead": // Remove if we decide that we should fully stop supporting push messages. Otherwise we need to build back up the push messages endpoints also through tasks-service.
    //   return ["/shared/my/notifications", "PUT", bodyData];
    case "product_price":
      return [`products/product_price?short_id=${short_id}&locale=${locale}`];
    case "product_basics":
      return [`products/product_basics?short_id=${short_id}&locale=${locale}`];
    case "products_basics":
      const short_ids = getQueryIdentifierValue(query, "short_ids");
      return [
        `products/products_basics?short_ids=${short_ids}&locale=${locale}`,
      ];
    case "nearby_products":
      const location = getQueryIdentifierValue(query, "location");
      return [`products/nearby_products?location=${location}`];
    case "grouped_locations":
      const full_id = getQueryIdentifierValue(query, "full_id");
      return [`locations/grouped_locations?full_id=${full_id}`];
    default:
      const [, forProduct] = method.split("tasks_for_product/");
      if (forProduct) return [`tasks/for_product?short_id=${forProduct}`];
      return [method, "GET"];
  }
}

export async function rerouteToTasksService(method, bodyData) {
  const [path, httpVerb, adjustedBody] = findV1Endpoint(method, bodyData);
  return callTasksService(path, adjustedBody || bodyData, httpVerb || "GET");
}

export async function callTasksService(path, bodyData = {}, httpVerb = "GET") {
  const url = `${TASKS_SERVICE_URL}/v1/${path}`;
  return new Promise((resolve) => perform(httpVerb, url, bodyData, resolve));
}

export async function createTask(data, images, presetTaskId = "") {
  let taskId = "";
  if (presetTaskId) {
    taskId = postToTasksService(
      "/v1/task/" + presetTaskId,
      {
        ...data,
        images,
      },
      "json"
    )
      .then((result) => {
        return result?.result?.taskId;
      })
      .catch((error) => {
        reportMessageToSentry(
          `ERROR_DOUBLE_WRITES_TASKS: Error while creating task in tasks service. TaskId: ${presetTaskId}. Status: ${error}`,
          {
            error,
            customErrorMessage: "ERROR_DOUBLE_WRITES_TASKS",
          }
        );
      });
  } else {
    taskId = postToTasksService("/v1/task/create", { ...data, images }, "json")
      .then((result) => {
        return result?.result?.taskId;
      })
      .catch((error) => {
        reportMessageToSentry(`Error while creating task in tasks service`, {
          error,
        });
      });
  }
  return taskId;
}

export async function updateTask(
  data,
  taskId,
  fixa_uid,
  additionalCustomAuthenticationHeaders = {}
) {
  if (data) {
    const path = `/v1/task/${taskId}/update`;
    const url = `${TASKS_SERVICE_URL}${path}`;
    return new Promise((resolve, reject) => {
      fetchAPI("PUT", url, data, "")
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          // commenting this for sometime, until all the in progress tasks are completed  to avoid too much logs in sentry
          /*  reportMessageToSentry(
            `ERROR_DOUBLE_WRITES_TASKS_UPDATE: Error while updating task in tasks service. TaskId: ${taskId}`,
            {
              error,
              customErrorMessage: "ERROR_DOUBLE_WRITES_TASKS_UPDATE",
            }
          ); */
          reject(error);
        });
    });
  }
}

export function getQueryIdentifierValue(query, identifier) {
  if (!query || query.length === 0) return "";

  let value = "";
  const queryParts = query.split("&");
  queryParts.forEach((queryPart) => {
    const idValue = queryPart.split("=");
    if (idValue[0] === identifier) {
      value = idValue[1];
      return;
    }
  });

  return value;
}
