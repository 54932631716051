import React, { useContext } from "react";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import taskTypes from "@coworker/app/src/components/TaskDetail/types";
import widgets from "@coworker/app/src/components/TaskDetail/widgets";
import { FixaModal } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModal";
import { FixaSheets } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaSheets";
import { FixaModalFooter } from "@coworker/apprestructured/src/shared/wrappers/FixaModal/FixaModalFooter";
import styles from "./task-detail-sheet.module.css";
import {
  useFixaUID,
  useTeamId,
  useUID,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { useWatchedTask } from "@coworker/app/src/hooks/useWatchedTasks";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";
import { ModalContext } from "../../../layout/context/ModalContextProvider";
import { ModalType } from "../../../layout/context/modalType";
import { useOnline } from "@coworker/app/src/core/hooks/useOnline";
import { AppBar } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import { useTaskOptionPopupMenu } from "@coworker/app/src/features/tasks/useTaskOptionPopupMenu";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import EllipsesHorizontalIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/EllipsesHorizontalIconPath";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";
import { useQueryClient } from "@tanstack/react-query";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

const TaskDetailSheet = () => {
  const { modalState, toggleModal } = useContext(ModalContext);
  const taskId = modalState.props?.taskDetail?.taskId || "";

  const queryClient = useQueryClient();

  const loadedTask = useWatchedTask(taskId);
  const [isDone, setIsDone] = React.useState(false);
  const task = React.useMemo(() => {
    return {
      ...loadedTask,
      isDone,
      setIsDone,
    };
  }, [isDone, loadedTask]);

  // Using state to display resolution instantly when a user enters and to avoid any backend slowness on a slow network.
  const [taskResolution, setTaskResolution] = React.useState(
    task?.finished_summary
  );
  const [picking, setPicking] = useUserPreference(
    profilePreferences.PICKING_DEFAULT,
    false
  );
  const { isOnline } = useOnline();
  const uid = useUID();
  const teamId = useTeamId();
  const fixaUid = useFixaUID();
  const userAndTeamIds = { uid, team_id: teamId, fixaUid };

  const handleToggleTaskDetailSheet = () => {
    toggleModal({ type: ModalType.TaskDetail });
  };

  const taskTypeConfig = taskTypes[task?.task_type];

  const { getTaskMenu } = useTaskOptionPopupMenu({ ...task, id: taskId });
  const closeMenu = () => {
    toggleModal({ type: ModalType.TaskDetail });
  };
  function openMenu(event) {
    event.stopPropagation();
    getTaskMenu();
  }

  const shouldShowActionsMenu =
    task?.state !== taskStateOptions.COMPLETED &&
    task?.state !== taskStateOptions.CLOSED;
  return (
    <FixaModal
      focusLockProps={{
        locked: false,
      }}
      visible={modalState.isTaskDetailSheetOpen}
      keepContentMounted={true}
      handleCloseBtn={handleToggleTaskDetailSheet}
    >
      <FixaSheets
        escapable="true"
        header={
          <>
            <div className={styles["task-detail-sheet-mobile-padding"]}>
              <AppBar
                title={""}
                actions={[
                  shouldShowActionsMenu && {
                    name: "menu",
                    icon: <FixaSSRIcon paths={EllipsesHorizontalIconPath} />,
                    position: "right",
                    onClick: openMenu,
                  },
                  {
                    name: "closeButton",
                    icon: <FixaSSRIcon paths={CrossIconPath} />,
                    position: "left",
                    onClick: closeMenu,
                  },
                ]}
              />
            </div>
          </>
        }
        footer={
          <FixaModalFooter stacked={true} renderBorder>
            {taskTypeConfig?.actions
              .filter(({ display }) =>
                display({
                  task,
                  profile: userAndTeamIds,
                  online: isOnline,
                  picking: task.task_type === "ADDON" && picking,
                })
              )
              .map(({ component: Action }, key) => (
                <div
                  key={key}
                  className={styles["action"]}
                  onClick={() => {
                    queryClient.invalidateQueries({
                      queryKey: ["activityTasks"],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["locations"],
                    });
                    queryClient.invalidateQueries({ queryKey: ["tasks"] });
                  }}
                >
                  <Action task={task} taskId={taskId} key={key} />
                </div>
              ))}
          </FixaModalFooter>
        }
      >
        {taskTypeConfig?.fields.flatMap((field, idx) => {
          const Widget = widgets[field.type];
          return [
            <Widget
              backArrowIsNeeded={false}
              needSticky={false}
              useAppBarMenu={false}
              picking={picking}
              setPicking={setPicking}
              key={field.type + idx}
              value={(field.values?.in || ((id) => id))(task)}
              taskId={taskId}
              task={task}
              taskPath={`${
                task?.task_type === "PLANNED" ? "repeatable_tasks" : "tasks"
              }/${taskId}/`}
              conf={field.conf}
              initialResolution={taskResolution || task?.finished_summary}
              onChange={(resolution) =>
                resolution !== undefined && setTaskResolution(resolution)
              }
            />,
          ];
        })}
      </FixaSheets>
    </FixaModal>
  );
};

export default TaskDetailSheet;
