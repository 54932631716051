import React from "react";
import RadioButton from "@ingka/radio-button";
import "@ingka/forms/dist/style.css";
import "@ingka/focus/dist/style.css";

type FixaRadioButtonProps = {
  id: string;
  value: string;
  checked: boolean;
  onChange: () => any;
  name: string;
  "data-testid"?: string;
};

function FixaRadioButton(props: FixaRadioButtonProps) {
  return <RadioButton {...props} />;
}

export default FixaRadioButton;
