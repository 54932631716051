import React from "react";
import styled from "styled-components";
import {
  ProductRow,
  useToastNotification,
  ShortLine,
  NormalLine,
} from "@coworker/components";
import { ImageSkeleton } from "@coworker/reusable/components/ImageSkeleton";
import { Trans } from "@coworker/locales";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { useInputPopup } from "../InputPopup";
import { useCreateTask } from "../../hooks/TaskActions/useCreateTask.js";
import {
  splitLocation,
  useAllSalesLocationsForArticleQuery,
} from "../../services/locations.service";
import { createProductLocationKey } from "./_helpers";
import { ReactComponent as UpvoteIcon } from "../../assets/svg/add-big.svg";
import tracker from "../../helpers/tracker";
import { useAvailableElevatedStock } from "../../hooks/useAvailableElevatedStock";
import {
  StyledInformationCircle,
  ProductIssueLinkContainer,
  ProductIssueLink,
} from "../InputPopup/CommonComponents";
import issueTypeOptions from "@coworker/functions/src/enums/issueTypes.json";
import { LoaderIcon } from "@coworker/reusable/Loader";
import useFlag, { FLAGS } from "../../hooks/useFlag";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";
import { useItemInfoAndPrice } from "@coworker/apprestructured/src/shared/hooks/item/useItemInfoAndPrice";
import { useLastAssignedTeamForItem } from "@coworker/apprestructured/src/tasks/hooks/useLastAssignedTeamForItem";

const StyledLoaderIcon = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
  padding: 9px;
`;

const Description = styled.div`
  color: var(--grey718);
  font-size: 14px;
`;

const BoldLocation = styled.p`
  font-weight: 600;
`;

const RowContainer = styled.div`
  display: flex;
  border-bottom: 1px solid var(--grey200);
  padding-bottom: 15px;
  height: 150px;

  & > div:first-child {
    width: 75%;
  }
`;

const AddIcon = styled(UpvoteIcon)`
  margin: auto;
  margin-right: 21px;
  height: 30px;
  width: 30px;
`;

const SkeletonContainer = styled.div`
  display: flex;
  margin: 26px 24px;
  min-height: 110px;
  border-bottom: 1px solid var(--grey150);
`;

const LineSkeletonContainer = styled.div`
  height: 150px;
  margin-left: 18px;

  & :not(:first-child) {
    margin-top: 12px;
  }

  & :first-child {
    margin-top: 0px;
  }
`;

function prepareTaskFields(initialTask, override) {
  // remove fields from initial task
  const {
    pick_quantity,
    refilled_quantity,
    refilled_type,
    task_finisher_id,
    ...baseTask
  } = initialTask;
  return {
    ...baseTask,
    ...override,
  };
}

function prepareLocation(locationCode, locationDepartment) {
  if (!locationCode || !locationDepartment) {
    return "";
  }
  return `${locationCode} - ${locationDepartment}`;
}

function ProductRowSkeleton() {
  const imageSize = 73;
  return (
    <SkeletonContainer>
      <ImageSkeleton size={imageSize} />
      <LineSkeletonContainer>
        <NormalLine />
        <ShortLine />
        <ShortLine />
      </LineSkeletonContainer>
    </SkeletonContainer>
  );
}

export default function NearbyAddonProductRow({
  type: itemType,
  art,
  location,
  teamName,
  initialTask,
  postTaskCreate,
}) {
  const productLocationKey = createProductLocationKey(itemType, art, location);
  const { push } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const { showToast } = useToastNotification();
  const { call: createTask } = useCreateTask();

  const { data: lastAssignedTeamForItem, isLoading: loadingLastAssignedTeam } =
    useLastAssignedTeamForItem(art);

  const { toggleModal } = React.useContext(ModalContext);

  const { data: itemInfo, isLoading: loadingItemInfo } =
    useItemInfoAndPrice(art);
  const { data: locationsData, isLoading: locationsLoading } =
    useAllSalesLocationsForArticleQuery(
      !!itemType && !!art ? `${itemType}${art}` : ""
    );
  const [locationCode, locationDepartment] = splitLocation(location);

  const { available, loading } = useAvailableElevatedStock(itemType + art);

  const [maxRefillForLocation, homeLocation, primaryLocation] =
    React.useMemo(() => {
      let current, home_location, primary_location;
      if (locationsData) {
        for (const loc of locationsData) {
          if (loc.location === locationCode) current = loc;
          if (loc.isHome || loc.isHomeBase) home_location = loc;
          if (loc.isPrimary) primary_location = loc;
        }
      }
      return [current?.quantity || "", home_location, primary_location];
    }, [locationCode, locationsData]);

  const pickupFieldsExperiment = useFlag(FLAGS.BETTER_PICKUP);
  const chosenPickupLocation = pickupFieldsExperiment
    ? homeLocation
    : primaryLocation;

  const createNearbyAddonTask = React.useCallback(async () => {
    const quantity = await getInput("selectAmount", {
      title: <Trans>piecesString</Trans>,
      maxAmount: maxRefillForLocation,
      available,
      productId: art,
      productArticleType: itemType,
      location,
      navigateToTaskForm: true,
      showTeamChange: true,
      lastAssignedTeam: lastAssignedTeamForItem,
    });

    if (!quantity) return;

    let pickupLocation = {
      pickup_location: prepareLocation(
        chosenPickupLocation?.storeLocation || chosenPickupLocation?.location,
        chosenPickupLocation?.locationDescription ||
          chosenPickupLocation?.descriptive
      ),
      pickup_location_custom: "",
      pickup_location_department:
        chosenPickupLocation?.locationDescription ||
        chosenPickupLocation?.descriptive ||
        "",
      pickup_location_grid_code:
        chosenPickupLocation?.storeLocation ||
        chosenPickupLocation?.location ||
        "",
    };

    if (!homeLocation) {
      const location = await getInput("addonLocation", {
        title: <Trans>pickupLocationString</Trans>,
        product_article_id: art,
        product_article_type: itemType,
      });

      if (!location) return;

      pickupLocation = {
        pickup_location: prepareLocation(
          location?.locationCode,
          location?.locationSection
        ),
        pickup_location_custom: location?.locationCustom || "",
        pickup_location_department: location?.locationSection || "",
        pickup_location_grid_code: location?.locationCustom
          ? ""
          : location?.locationCode,
      };
    }

    const createNewProductIssue = () => {
      push("/task/new/product", {
        product: {
          product: art,
          issue_type: issueTypeOptions.OUT_OF_STOCK,
        },
        location: location,
        isSelectProductIssueType: true,
      });
      tracker.taskEfficiency.trackCreateOutOfStockProductIssue();
    };

    if (!loading && available === 0) {
      tracker.taskEfficiency.trackWarnOutOfStock();
      const response = await getInput("confirmation", {
        question: <Trans>outOfStockWarningTitle</Trans>,
        description: (
          <>
            <Trans>outOfStockWarningDescriptionString</Trans>
            <ProductIssueLinkContainer>
              <StyledInformationCircle />
              <ProductIssueLink onClick={createNewProductIssue}>
                <span>
                  <Trans>createProductIsuueString</Trans>
                </span>
              </ProductIssueLink>
            </ProductIssueLinkContainer>
          </>
        ),
        positiveText: <Trans>continue2String</Trans>,
        negativeText: <Trans>cancelString</Trans>,
        swapButtons: true,
      });

      if (!response) return;
    }

    const { amount, actualAmount, amountType, assignedTeamId, assignedUserId } =
      quantity;
    const { name, paNo, smallImageUrl, salesPrice, type } = itemInfo;
    const task = prepareTaskFields(initialTask, {
      product_article_price: Number(salesPrice?.price),
      product_article_currency: salesPrice?.currency,
      product_article_price_missing: false,
      product_article_type: type,
      product_article_id: art,
      item_name: name,
      item_type: "",
      item_color: "",
      item: {
        image_small: smallImageUrl || "",
        measurements: "",
      },
      panumber: paNo,
      assigned_user_id: assignedUserId || "",
      location,
      location_custom: "", // so it cannot override it
      location_department: locationDepartment,
      location_grid_code: locationCode,
      requested_quantity: amount,
      actual_requested_quantity: actualAmount,
      requested_type: amountType,
      refilled_by_me: false,
      state: assignedUserId
        ? taskStateOptions.ASSIGNED
        : taskStateOptions.UNASSIGNED,
      pick_quantity: null,
      refilled_quantity: null,
      assigned_team_id: assignedTeamId, // nearby addons can be assigned to others in amount screen.
      task_type_detail: "PICK_AND_REFILL",
      ...pickupLocation,
    });

    postTaskCreate(productLocationKey);
    const id = await createTask(task, []);

    tracker.taskEfficiency.trackNearbyAddonAdded();

    showToast(
      <Trans count={1}>taskCreatedString</Trans>,
      <Trans>curtainViewString</Trans>,
      () => {
        toggleModal({
          type: ModalType.TaskDetail,
          payload: { taskId: id },
        });
      }
    );
  }, [
    homeLocation,
    art,
    createTask,
    getInput,
    initialTask,
    location,
    locationCode,
    locationDepartment,
    postTaskCreate,
    productLocationKey,
    push,
    showToast,
    itemType,
    lastAssignedTeamForItem,
    maxRefillForLocation,
    chosenPickupLocation,
    available,
    loading,
    itemInfo,
    toggleModal,
  ]);

  const noProduct = art && !loadingItemInfo && !itemInfo;
  React.useEffect(() => {
    if (noProduct) console.log(`Ignored product ${art}`);
  }, [noProduct, art]);

  const stillLoading =
    loadingItemInfo || locationsLoading || loadingLastAssignedTeam;

  if (noProduct) return null;
  return !loadingItemInfo && itemInfo ? (
    <RowContainer onClick={createNearbyAddonTask}>
      <ProductRow
        hideArrow={true}
        border={false}
        description={itemInfo?.description}
        image={itemInfo?.smallImageUrl}
        title={`${itemInfo?.name} • ${itemInfo?.noFormatted}`}
        children={
          stillLoading ? (
            <StyledLoaderIcon />
          ) : (
            <Description>
              <BoldLocation>{locationCode}</BoldLocation>
              {lastAssignedTeamForItem?.name || teamName}
            </Description>
          )
        }
      />
      {!stillLoading && <AddIcon />}
    </RowContainer>
  ) : (
    <ProductRowSkeleton />
  );
}
