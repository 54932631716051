import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { StoreBox } from "./StoreBox";
import Filters from "./Filters";
import HomeAndAwayBox from "./HomeAndAwayBox";
import { useInsightsFetchParams } from "./helpers";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { Departments } from "./Departments";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";
import { INSIGHTS_HOME_PAGE } from "@coworker/functions/src/enums/insightsRequestTypes";
import { HeaderSearchInput } from "../../features/searching/HeaderSearchInput";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import tracker from "../../helpers/tracker";
import { isRDTDevice } from "@coworker/reusable";
import { useInputPopup } from "../InputPopup";
import { parseProduct } from "../../services/products.service";
import { useFocus } from "../../hooks/useFocus";
import { AppBar } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import MagnifyingGlassIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/MagnifyingGlassIconPath";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import InsightsDeprecationBanner from "./InsightsDeprecationBanner";

const HeadingFollowerContainer = styled.div`
  position: sticky;
  width: 100%;
  z-index: var(--z-heading-follower);
  border-bottom: 1px solid var(--grey100);
  top: 56px;
`;

const validSearchInput = /^((\d\.?)+)?$/;
const STORE_CHOICE_KEY = "insights_store_choice";

const Insights = ({ product_id, location_id }) => {
  const store_id = useStoreId();

  React.useEffect(() => {
    sessionStorage.removeItem(STORE_CHOICE_KEY);
  }, []);
  const [showSearch, setShowSearch] = React.useState(false);
  const [productNumber, setProductNumber] = React.useState("");
  const { inputRef } = useFocus();
  const [focus, setFocus] = React.useState(true);
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const isRDT = isRDTDevice();
  const { getInput } = useInputPopup();

  const [dataFetchParams, onChangeInsightsParams, isFetchingSavedFilters] =
    useInsightsFetchParams({
      store_id,
      base: {
        weeksBack: 0,
        order_by: "refill",
        order_by_asc: false,
        graph_type: "pieces",
      },
      location_id,
      product_id,
    });

  const activeHFB = useCallInsightsFunction(INSIGHTS_HOME_PAGE, {
    ...dataFetchParams,
    isFetchingSavedFilters,
  });

  const pushToProductPage = (productId) => {
    tracker.insights.trackProductSearch("app");
    const [short_id, type] = parseProduct(productId);
    const updatedInsightsFilter = {
      deptFilters: {
        hfb: "ALL",
        salesShare: "",
      },
    };
    onChangeInsightsParams(updatedInsightsFilter);
    push(`/insights/product/${type || "ART"}${short_id}`);
  };

  const update = (string, focus) => {
    if (string.match(validSearchInput)) {
      setProductNumber(string);
      setFocus(focus);
      if (focus) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
      // Just temporary, once we have auto complete search either by product number or product name this will change
      const shortId = string.trim().replace(/[.]/g, "");
      if (shortId.length === 8) {
        pushToProductPage(shortId);
      }
    }
  };
  const getProductNumber = async () => {
    const response = await getInput("number", {
      bigTitle: <Trans>searchArticleIdString</Trans>,
    });
    if (response && response.length === 8) {
      pushToProductPage(response);
    } else {
      tracker.insights.trackProductIdNot8Digits(response);
    }
  };

  return (
    <>
      <AppBar
        title={t("addonInsightsString")}
        actions={[
          {
            name: "search",
            icon: <FixaSSRIcon paths={MagnifyingGlassIconPath} />,
            onClick: () => (isRDT ? getProductNumber() : setShowSearch(true)),
            position: "right",
          },
        ]}
      />
      {isFetchingSavedFilters && <PositionedLoaderIcon />}
      {!isFetchingSavedFilters && (
        <>
          {showSearch && (
            <HeaderSearchInput
              inputRef={inputRef}
              onChange={(e) => update(e.target.value, true)}
              onClose={() => {
                setProductNumber("");
                update("", true);
              }}
              onCancel={() => {
                setShowSearch(false);
              }}
              focus={focus}
              placeholder={t("searchArticleIdString")}
              query={productNumber}
            />
          )}
          <InsightsDeprecationBanner padding="16px 16px 0 16px" />
          <StoreBox
            dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
            onChangeInsightsParams={onChangeInsightsParams}
          />
          <HeadingFollowerContainer data-testid="filter-container">
            <Filters
              onChangeInsightsParams={onChangeInsightsParams}
              dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
              activeHFB={activeHFB}
              level={1}
            />
          </HeadingFollowerContainer>
          <HomeAndAwayBox level="HOME" />
          <Departments
            onChangeInsightsParams={onChangeInsightsParams}
            activeHFB={activeHFB}
            dataFetchParams={{ ...dataFetchParams, isFetchingSavedFilters }}
          />
        </>
      )}
    </>
  );
};

export default Insights;
