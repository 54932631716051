import React from "react";
import styles from "./task-card.module.css";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import ImageIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/ImageIconPath";
import SpeechBubbleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/SpeechBubbleIconPath";
import { useAllImages } from "@coworker/app/src/hooks/useTaskImages";
import { useTaskNotes } from "@coworker/app/src/hooks/useTaskNotes";
import { FixaWindow } from "@coworker/reusable";

export const ImagesAndComments = ({
  taskId,
  hasNotes,
  hasImages,
}: {
  taskId: string;
  hasNotes: boolean;
  hasImages: boolean;
}) => {
  const callTasksService = (window as FixaWindow).callTasksService;
  const images = useAllImages(taskId, !callTasksService);
  const notes = useTaskNotes(taskId, !callTasksService);
  return (
    <div className={styles["icon-wrapper"]}>
      {(images.length > 0 || hasImages) && (
        <FixaSSRIcon
          paths={ImageIconPath}
          className={styles["icon"] as string}
        />
      )}
      {(notes.length > 0 || hasNotes) && (
        <FixaSSRIcon
          paths={SpeechBubbleIconPath}
          className={styles["icon"] as string}
        />
      )}
    </div>
  );
};
