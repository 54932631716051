import React from "react";
import styled, { css } from "styled-components";
import FixaPill from "@coworker/apprestructured/src/shared/wrappers/FixaPill/FixaPill";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import FiltersIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/FiltersIconPath";
import { useInputPopup } from "..";
import {
  areaIsWholeStore,
  defaultCustomPeriod,
  describeDivision,
  describeFilter,
  getAreaFilter,
  getDivisionFilter,
  getPeriodFilter,
  periodIsCustom,
} from "./filterChoices";
import { Trans } from "@coworker/locales";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import useFormatter from "../../../hooks/useFormatter";
import "../../TestBuy/CommonComponents/styles.css";

const Row = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey200);
  overflow-y: hidden;
  overflow-x: auto;
  align-items: center;
  & > *:first-child {
    margin-left: 16px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey300);
      pointer-events: none;
    `};
`;

export default function UserFiltersRow({
  filters,
  disabled = false,
  settingsPrefix,
}) {
  if (!filters?.[0].startsWith("period=")) filters?.reverse();
  const { getInput } = useInputPopup();

  return (
    <Row
      data-testid="user-filters-row"
      disabled={disabled}
      onClick={() => {
        if (disabled) return;
        getInput("userFilters", {
          title: <Trans>refineString</Trans>,
          actionText: <Trans>applyFiltersString</Trans>,
          filters,
          settingsPrefix,
          hideShowTasksToggle:
            settingsPrefix !== profilePreferences.MFAQ_PREFIX,
        });
      }}
    >
      <FixaButton
        size="xsmall"
        type={"tertiary"}
        iconOnly={true}
        ssrIcon={FiltersIconPath}
        aria-label={<Trans>filtersButtonAriaString</Trans>}
      />
      <FilterPills
        filters={filters}
        disabled={disabled}
        settingsPrefix={settingsPrefix}
      />
    </Row>
  );
}

function FilterPills({ filters, disabled, settingsPrefix }) {
  const [showOnlyMFAQsWithTasks] = useUserPreference(
    settingsPrefix + profilePreferences.SHOW_QUESTIONS_WITH_TASKS,
    false
  );
  const describedFilters = useDescribedFilters(filters, settingsPrefix);
  return (
    <React.Fragment>
      {describedFilters.map((filter) => (
        <FixaPill
          size="small"
          disabled={disabled}
          key={filter?.props?.children || filter}
          label={filter}
          className="pill-gap"
          aria-label={`${filter} ${(<Trans>filterPillAriaString</Trans>)} `}
        />
      ))}
      {showOnlyMFAQsWithTasks &&
        settingsPrefix === profilePreferences.MFAQ_PREFIX && (
          <FixaPill
            size="small"
            disabled={disabled}
            label={<Trans>onlyQuestionsTasksString</Trans>}
            className="pill-gap"
            aria-label={`${(<Trans>onlyQuestionsTasksString</Trans>)} pill`}
          />
        )}
    </React.Fragment>
  );
}

function useDescribedFilters(filters, settingsPrefix) {
  const { formatDateRange } = useFormatter();
  const [[startDate, endDate]] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_CUSTOM_PERIOD,
    defaultCustomPeriod()
  );
  const periodFilter = getPeriodFilter(filters);
  const areaFilter = getAreaFilter(filters);
  const divisionFilter = getDivisionFilter(filters);
  const divisionName = divisionFilter && describeDivision(divisionFilter);

  return React.useMemo(
    () => [
      periodIsCustom(periodFilter)
        ? formatDateRange(startDate, endDate)
        : describeFilter(periodFilter),
      divisionFilter ? (
        areaIsWholeStore(areaFilter) ? (
          <Trans>{divisionName}</Trans>
        ) : (
          <>
            {" "}
            <Trans>{describeFilter(areaFilter)}</Trans>{" "}
            <Trans>{divisionName}</Trans>
          </>
        )
      ) : (
        describeFilter(areaFilter)
      ),
    ],
    [
      areaFilter,
      endDate,
      formatDateRange,
      periodFilter,
      startDate,
      divisionFilter,
      divisionName,
    ]
  );
}
