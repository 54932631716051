import React from "react";
import Layout from "./Layout";
import routes from "../routes";
import { InputPopup, InputPopupProvider } from "./InputPopup";
import ScanResult, { ScanPopoverProvider } from "./ScanResult";
import {
  ToastNotification,
  ToastNotificationProvider,
} from "@coworker/components";
import {
  CurtainNotification,
  CurtainNotificationProvider,
} from "./CurtainNotification";
import { ChangeTeamWrapper } from "./ChangeTeamWrapper";
import { TooltipProvider } from "./Tooltip";
import { WorkspacesProvider } from "../hooks/useWorkspaces";
import { useInputPopup } from "./InputPopup";
import { useSetInitialLanguage } from "./LanguageSetter";
import {
  setStartedAtVersion,
  setUserId,
  setUserStore,
  setCountry,
} from "../helpers/tracker";
import versionJson from "@coworker/functions/src/version.json";
import { updateUser } from "../services/user.service";
import { useConfigureSentry } from "../hooks/useConfigureSentry";
import PinCodeController from "./PinCode/PinCodeController";
import { SelectedTasksContext } from "./SelectedTasksProvider";
import { useMultiSelect } from "../hooks/useMultiSelect";
import {
  useStoreId,
  useCountryId,
  useStartedAtVersion,
  useHashedUid,
  useFixaUID,
  useUserPermissions,
} from "../core/auth/useLoggedInUser";
import { getCachedInternalApi } from "../hooks/API/useCallInternal";
import { FLAGS } from "../hooks/useFlag";
import { isRDTDevice } from "@coworker/reusable";
const ONE_DAY = 24 * 3600 * 1000;

function BrowserBackInputPopupWatcher() {
  const { closePopup } = useInputPopup();
  React.useEffect(() => {
    function listen() {
      closePopup();
    }
    window.addEventListener("popstate", listen);
    return () => window.removeEventListener("popstate", listen);
  }, [closePopup]);
  return null;
}

function useSetAnalyticsUserProperties() {
  const fixaUid = useFixaUID();
  const country_id = useCountryId();
  const store_id = useStoreId();
  const hashed_uid = useHashedUid();
  const started_at_version = useStartedAtVersion();

  React.useEffect(() => {
    if (store_id) {
      setUserStore(store_id);
      const version = started_at_version || versionJson.version;
      setStartedAtVersion(version);
    }
    if (fixaUid) setUserId(fixaUid, hashed_uid);
    if (country_id) setCountry(country_id);
  }, [store_id, started_at_version, fixaUid, country_id, hashed_uid]);
}

function useMarkUserActive() {
  const [profile, setProfile] = React.useState({});
  //TODO get user last actiivty and update later
  const fixaUid = useFixaUID();

  React.useEffect(() => {
    getCachedInternalApi(
      `user?fixa_uid=${fixaUid}`,
      (response) => {
        setProfile(response?.data?.data);
      },
      0,
      false,
      "CORE_SERVICE"
    );
  }, [fixaUid]);

  const last_activity = profile?.last_activity;
  const date = new Date().toISOString().slice(0, 10); // Used to trigger so we update last_activity daily
  React.useEffect(() => {
    const now = Date.now();
    if (fixaUid && (!last_activity || last_activity < now - ONE_DAY)) {
      updateUser(fixaUid, { last_activity: now });
    }
  }, [fixaUid, last_activity, date]);
}

function Setters() {
  useConfigureSentry();
  useSetAnalyticsUserProperties();
  useSetInitialLanguage();
  useMarkUserActive();
  return null;
}

function debugHelpMessageHandler(event) {
  const data = event?.data;
  const hide =
    `${data?.source}`.includes("react-") ||
    `${data?.type || data}`.includes("webpack");
  if (!hide && data) console.log("postMessage recieved!", data);
}

if (window.location.host.indexOf("localhost") !== -1) {
  window.addEventListener("message", debugHelpMessageHandler, false);
}

export default function Main() {
  const isRDT = isRDTDevice();

  const multiSelectState = { my: useMultiSelect(), open: useMultiSelect() };

  const userPermissions = useUserPermissions();

  window.callTasksService = userPermissions?.includes(FLAGS.CALL_TASKS_SERVICE);
  window.callTestbuyService = userPermissions?.includes(
    FLAGS.CALL_TESTBUY_SERVICE
  );
  window.finalInsightsFromTasksServiceFlag = userPermissions?.includes(
    FLAGS.CALL_TASKS_SERVICE_INSIGHTS
  );
  window.callMFAQService = userPermissions?.includes(FLAGS.CALL_MFAQ_SERVICE);
  const forwardToTasksService = userPermissions?.includes(
    FLAGS.FORWARD_TO_TASKS_SERVICE
  );
  window.forwardToTasksService = window.callTasksService
    ? false
    : forwardToTasksService;
  window.sprTestbuy = userPermissions?.includes(FLAGS.SPR_TESTBUY);
  window.hideForGermanyUsers = userPermissions?.includes(FLAGS.GERMANY_ROLLOUT);
  window.hideExcelExport = userPermissions?.includes(FLAGS.HIDE_EXCEL_EXPORT);
  window.showEDSdate = userPermissions?.includes(FLAGS.SHOW_EDS_DATE);
  window.acquireTokenPopup = userPermissions?.includes(
    FLAGS.ACQUIRE_TOKEN_POPUP
  );
  window.enableDiscoverNow = userPermissions?.includes(
    FLAGS.ENABLE_DISCOVER_NOW
  );

  return (
    <React.Fragment>
      <Setters />
      <InputPopupProvider>
        <InputPopupProvider>
          <WorkspacesProvider>
            <TooltipProvider>
              <ScanPopoverProvider>
                <CurtainNotificationProvider>
                  <ToastNotificationProvider>
                    <ChangeTeamWrapper>
                      <SelectedTasksContext.Provider value={multiSelectState}>
                        <Layout routes={routes} />
                        <InputPopup />
                      </SelectedTasksContext.Provider>
                      <ScanResult />
                      <CurtainNotification />
                      <ToastNotification />
                      <BrowserBackInputPopupWatcher />
                      {isRDT && <PinCodeController />}
                    </ChangeTeamWrapper>
                  </ToastNotificationProvider>
                </CurtainNotificationProvider>
              </ScanPopoverProvider>
            </TooltipProvider>
          </WorkspacesProvider>
        </InputPopupProvider>
        <InputPopup />
      </InputPopupProvider>
    </React.Fragment>
  );
}
