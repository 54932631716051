import React from "react";
import styled from "styled-components";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { Trans, useTranslation } from "@coworker/locales";
import { HeaderSearchInput } from "../../features/searching/HeaderSearchInput";
import { HeaderSearchButton } from "../../features/searching/HeaderSearchButton";
import { SearchSuggestion, Button, SearchResult } from "@coworker/components";
import Scanner from "../Scanner";
import { useDebouncedValue, useDelayedValue } from "../../hooks/useDebounce";
import { useScanPopover } from "../ScanResult";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { useMixedSearchProducts } from "../../hooks/useSearchProducts";
import { parseProduct } from "../../services/products.service";
import useAutocompleteProducts from "../../hooks/useAutocompleteProducts";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import useMounted from "../../hooks/useMounted";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import RDTScanner from "./RDTScanner";
import { isAnyRDT } from "@coworker/reusable/helpers/devices";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { BrowserScanner } from "../Scanning/BrowserScanner";
import useBrowserScanner from "../Scanning/useBrowserScanner";
import { Tip } from "../Scanning/ScanTip";
import { useFocus } from "../../hooks/useFocus";
import { SimpleToast } from "@coworker/apprestructured/src/shared/simple/SimpleToast/SimpleToast";
import { ArticleError } from "@coworker/apprestructured/src/shared/components/ArticleError/ArticleError";
import { useQueryClient } from "@tanstack/react-query";
import { fetchItemPriceAndCurrency } from "@coworker/apprestructured/src/shared/hooks/item/useItemPriceAndCurrency";

const Container = styled.div`
  position: fixed;
  background: var(--white);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
`;

const LoadMoreButton = styled(Button)`
  margin: 10px auto;
`;

const SearchResultsContainer = styled.div`
  border-top: 1px solid var(--grey150);
  margin-top: 70px;
  min-height: calc(100% - 71px);
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const ResultWrap = styled.div``;

const Loader = styled(LoaderIcon)`
  width: 30px;
  margin: 20px auto;
  display: block;
`;

const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  font-size: 14px;
  text-align: center;
  padding: 20px 25px 20px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SlowLoadingContainer = styled.div`
  margin: auto;
`;

const TryAgainContainer = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding: 20px 25px 20px 25px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1440px) {
    padding-bottom: 128px;
  }
`;

const ErrorContainer = styled(LoadingContainer)`
  justify-content: flex-end;

  *:first-child {
    padding-bottom: 100%;
  }
`;

const History = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 28px;
  height: 60px;
  font-size: 13px;

  & > p {
    cursor: pointer;
  }
`;

const HistoryTitle = styled.div`
  color: var(--grey500);
`;

const NoResultsContainer = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey700);
  padding: 20px 29px 9px 29px;
`;

const SPRArticlesExcludedWarning = styled.div`
  padding-top: 10px;
`;

function matchesStringCaseInsesitive(p, query) {
  return p?.toLowerCase() === query?.toLowerCase();
}

const Search = ({ onClose, resolve, hideSPR, withHistory, activeRef }) => {
  const { t } = useTranslation();
  const { setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const [query, setQuery] = React.useState("");
  const debouncedQuery = useDebouncedValue(query, window.Cypress ? 210 : 100);
  const { inputRef } = useFocus();
  const [focus, setFocus] = React.useState(true);
  const {
    data: proposals,
    loading: loadingSuggestions,
    dataIsFromAPI: finishedLoadingSuggestions,
    error: autocompleteError,
  } = useAutocompleteProducts(query, {
    blockRequest: !query,
  });

  const {
    data,
    dupesMFS,
    dupesSIK,
    hiddenMFS,
    hiddenSIK,
    totalMFS,
    totalSIK,
    RESULT_LIMIT_PER_PAGE,
    error: searchProductsError,
    page,
    loading,
    nextPage,
  } = useMixedSearchProducts(debouncedQuery, {
    proposals,
    finishedLoadingSuggestions,
    hideSPR,
    blockRequest: !debouncedQuery,
    includeNumberSearch: true, // Here we should include product number search to cover all food products etc that aren't included in either of SIK/MFS search results.
  });

  const [searchHistory, setSearchHistory] = useUserPreference(
    profilePreferences.SEARCH_HISTORY,
    []
  );

  const showDelayedWarning = useDelayedValue(loading && query, 4000);

  React.useEffect(() => {
    let mounted = true;
    if (inputRef.current) {
      const onFocus = () => {
        setTimeout(() => {
          if (mounted) {
            setFocus(true);
          }
        }, 0);
      };
      const onBlur = () => {
        setTimeout(() => {
          if (mounted) {
            setFocus(false);
          }
        }, 0);
      };
      const input = inputRef.current;
      input.addEventListener("focus", onFocus);
      input.addEventListener("blur", onBlur);
      return () => {
        mounted = false;
        input.removeEventListener("focus", onFocus);
        input.removeEventListener("blur", onBlur);
      };
    }
  }, [inputRef]);

  const update = (string, focus) => {
    // Checking if the query only contains numbers (scanner)
    if (/^\d+$/.test(string)) {
      setQuery(string.slice(0, 8));
    } else {
      setQuery(string);
    }
    setFocus(focus);
    if (focus) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  };
  React.useEffect(() => {
    if (withHistory && navigationState) {
      if (navigationState.query !== query && query && activeRef.current) {
        setNavigationState({ query });
      }
    }
  }, [withHistory, query, navigationState, setNavigationState, activeRef]);

  React.useEffect(() => {
    activeRef.current = true;
    if (navigationState && navigationState.query && withHistory) {
      update(navigationState.query, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = data.slice(0, page * RESULT_LIMIT_PER_PAGE);
  const amountMore =
    totalMFS -
    dupesMFS -
    hiddenMFS +
    (totalSIK - dupesSIK - hiddenSIK) -
    items.length;

  const onSearchResultItemClick = async (short_id, type, item) => {
    resolve(short_id, type, item);
    setSearchHistory(truncatedList(searchHistory, query.toLocaleLowerCase()));
  };

  return (
    <Container data-testid="searchContainer">
      <HeaderContainer>
        <HeaderSearchInput
          inputRef={inputRef}
          onChange={(e) => update(e.target.value, true)}
          onClose={() => {
            setNavigationState({ query: "" });
            update("", true);
          }}
          onCancel={() => {
            activeRef.current = false;
            onClose();
          }}
          focus={focus}
          placeholder={t("searchString")}
          query={query}
        />
      </HeaderContainer>
      <SearchResultsContainer data-testid="searchResults">
        {!autocompleteError && !searchProductsError && loadingSuggestions && (
          <Loader />
        )}
        {!loading && !query && !!searchHistory.length && (
          <History data-testid="searchHistoryHeader">
            <HistoryTitle>
              <Trans>searchHistoryString</Trans>
            </HistoryTitle>
            <p onClick={() => setSearchHistory([])} data-testid="clearHistory">
              <Trans>clearHistoryString</Trans>
            </p>
          </History>
        )}
        {!loading &&
          !query &&
          !!searchHistory.length &&
          searchHistory.map((p) => (
            <SearchSuggestion
              clickable
              key={p}
              onClick={() => update(p, false)}
              text={p}
            />
          ))}
        {!loadingSuggestions && query && (
          <div data-testid="autocomplete-suggestions">
            {!searchProductsError &&
              proposals
                .filter((p) => !matchesStringCaseInsesitive(p, query))
                .map((p) => (
                  <SearchSuggestion
                    clickable
                    key={p}
                    onClick={() => update(p, false)}
                    text={p}
                    uppercase
                  />
                ))}
          </div>
        )}
        {!loading &&
          query.length > 0 &&
          debouncedQuery &&
          !loadingSuggestions &&
          items.length === 0 && (
            <NoResultsContainer>
              <div>{t("noResultsForString", { query })}</div>
              <SPRArticlesExcludedWarning>
                {hideSPR ? t("sprArticlesExcluded") : ""}
              </SPRArticlesExcludedWarning>
            </NoResultsContainer>
          )}

        {(!loading || page > 1) && query && (
          <ResultWrap data-testid="searchResultWrap">
            {items &&
              items.map((item, index) => {
                return (
                  <SearchResult
                    testId={`searchResult${index}`}
                    key={item.no}
                    description={item.description}
                    itemName={item.name}
                    formattedNumber={item.noFormatted}
                    image={item.imageUrl}
                    onClick={() =>
                      onSearchResultItemClick(item.no, item.type, item)
                    }
                  />
                );
              })}
          </ResultWrap>
        )}
        {(autocompleteError || searchProductsError) && (
          <ErrorContainer>
            <Trans>searchErrorString</Trans>
            <TryAgainContainer
              onClick={() => {
                setQuery("");
              }}
            >
              <Trans>tryAgainString</Trans>
            </TryAgainContainer>
          </ErrorContainer>
        )}
        {loading && (
          <LoadingContainer>
            <Loader />
            {!!showDelayedWarning && (
              <SlowLoadingContainer>
                <Trans>slowLoadingInfoString</Trans>
              </SlowLoadingContainer>
            )}
          </LoadingContainer>
        )}
        {!loading && amountMore > 0 && query && (
          <LoadMoreButton
            customMargin
            data-testid="searchResultMoreButton"
            dark={true}
            primary={true}
            onClick={() => nextPage()}
            text={t("amountMoreString", { count: amountMore })}
          />
        )}
      </SearchResultsContainer>
    </Container>
  );
};

function truncatedList(items, newItem) {
  const list = [newItem.trim()];
  items.forEach((item) => {
    const clean = item && item.trim();
    if (clean && list.length < 20 && !list.includes(clean)) list.push(clean);
  });
  return list;
}

const ScanContainer = styled.div`
  position: fixed;
  background: var(--white);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

function Scan({
  onScannerError,
  startSearch,
  onCancel,
  paused,
  onBarcode,
  videoRef,
}) {
  const [pause, setPause] = React.useState(false);
  const [profileSetToRDTScannerMode, setInRDTScannerMode] = useUserPreference(
    profilePreferences.USE_RDT_SCANNER,
    isAnyRDT()
  );
  const inRDTScannerMode = isAnyRDT() && profileSetToRDTScannerMode;
  const canUseBrowserScanner = useBrowserScanner();

  return (
    <ScanContainer>
      {inRDTScannerMode ? (
        <RDTScanner
          switchToCameraScanner={() => setInRDTScannerMode(false)}
          onBarcode={onBarcode}
        />
      ) : canUseBrowserScanner ? (
        <BrowserScanner
          videoRef={videoRef}
          onBarcode={onBarcode}
          switchToRDTScanner={() => setInRDTScannerMode(true)}
        />
      ) : (
        <Scanner
          videoRef={videoRef}
          onBarcode={onBarcode}
          onError={onScannerError}
          scanningPaused={paused || pause}
          streamPaused={pause || paused}
          switchToRDTScanner={() => setInRDTScannerMode(true)}
        />
      )}

      <HeaderContainer>
        <HeaderSearchButton
          onClick={() => {
            setPause(true); // Pause scanning to avoid crashing
            startSearch();
          }}
          onCancel={() => {
            setPause(true);
            onCancel();
          }}
        />
        {!inRDTScannerMode && (
          <Tip scanner={canUseBrowserScanner}>
            <Trans>scanTip</Trans>
          </Tip>
        )}
      </HeaderContainer>
    </ScanContainer>
  );
}

function ScanAndSearchWithoutRouter({
  onSubmit,
  onClose,
  searchPopup,
  withHistory = true,
  start,
  hideSPR = false,
  forbiddenArticles = [], // Articles that should not be returned
  onForbiddenArticle = () => null, // Called when a forbidden article is scanned
}) {
  const activeRef = React.useRef(true);
  const { push, setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const { setScannedItem, scanPopoverActive } = useScanPopover();
  const [inSearch, setInSearch] = React.useState(navigationState.inSearch);
  const [scannerError, setScannerError] = React.useState(false);
  const [toastVisible, setToastVisible] = React.useState(false);
  const store_id = useStoreId();
  const videoRef = React.useRef(null);
  const [showArticleErrorModal, setShowArticleErrorModal] =
    React.useState(false);
  const queryClient = useQueryClient();
  const onCloseArticleErrorModal = (closeArticleErrorModal) => {
    setShowArticleErrorModal(closeArticleErrorModal);
    setScannedItem(null);
    setInSearch(false);
    setNavigationState({
      code: null,
      supplierNumber: null,
      search: true,
      type: "addon",
    });
    onClose();
  };

  const onBarcode = async (
    code,
    fromSearch,
    product,
    supplierNumber,
    primaryLocale
  ) => {
    if (forbiddenArticles.includes(code)) {
      if (window.location.pathname === "/testbuy/custom") {
        return setToastVisible(true);
      } else {
        return onForbiddenArticle(code);
      }
    }
    if (withHistory) {
      setNavigationState({ code, product, fromSearch });
      setNavigationState({
        code,
        product,
        fromSearch,
        supplierNumber,
      });
    }

    if (navigationState.type === "addon") {
      let isItemPriceMissing = false;
      const params = {
        supplierNumber,
        product: (product?.number || code).replace(/\./g, ""),
        type: product?.type || "",
      };
      if (!product) {
        if (code === "https://") return;
        const itemPriceAndCurrencyInfo = await queryClient.ensureQueryData(
          ["item", "price", code, primaryLocale],
          () => fetchItemPriceAndCurrency(code, primaryLocale)
        );
        isItemPriceMissing =
          !itemPriceAndCurrencyInfo?.price ||
          !itemPriceAndCurrencyInfo?.currency;
      } else {
        isItemPriceMissing =
          !product?.salesPrice?.currency || !product?.salesPrice?.price;
      }
      if (isItemPriceMissing) {
        setShowArticleErrorModal(true);
        return;
      }
      activeRef.current = true;
      onSubmit(params);
      setScannedItem(null);
      setTimeout(() => videoRef.current?.play(), 0);
      if (withHistory && activeRef.current) {
        setNavigationState({ code: null, supplierNumber: null });
      }
    } else {
      setScannedItem({
        code,
        supplierNumber,
        product,
        store_id,
        withContinue: true,
        displayRelatedTasks: true,
        start: start,
        fromSearch: fromSearch,
        onSubmit: (...params) => {
          activeRef.current = false;
          onSubmit(...params);
          setScannedItem(null);
        },
        onRelated: () => {
          activeRef.current = false;
          onClose();
          setScannedItem(null);
          push(`/tasks/related/${code}`);
        },
        close: () => {
          // This is here to force the video stream to start playing again if the user closes the popup when an article is found.
          // The setTimeout(..., 0) is needed so that it reorders execution, and starts playing again after react has rerendered the scanner component.
          setTimeout(() => videoRef.current?.play(), 0);

          if (withHistory && activeRef.current) {
            setNavigationState({ code: null, supplierNumber: null });
          }
        },
        onItemClick: (code) => {
          activeRef.current = false;
          onClose();
          setScannedItem(null);
          const [short_id, type] = parseProduct(code);
          if (type) push(`/product/${type + short_id}`);
        },
        onSearch: () => {
          setScannedItem(null);
          setInSearch(true);
        },
      });
    }
  };

  React.useEffect(() => {
    if (navigationState && navigationState.inSearch && withHistory) {
      setInSearch(true);
    }
    if (navigationState && navigationState.code && withHistory) {
      onBarcode(
        navigationState.code,
        navigationState.fromSearch,
        navigationState.product,
        navigationState.supplierNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      navigationState &&
      navigationState.inSearch !== inSearch &&
      activeRef.current
    ) {
      withHistory && setNavigationState({ inSearch });
    }
  }, [inSearch, withHistory, navigationState, setNavigationState]);

  const [pauseScanner, setPauseScanner] = React.useState(false);
  return (
    <React.Fragment>
      {inSearch && (
        <Search
          onClose={() => {
            activeRef.current = false;
            if (scannerError) {
              onClose();
            } else {
              setInSearch(false);
              setPauseScanner(false);
              videoRef.current && videoRef.current.play();
            }
          }}
          hideSPR={hideSPR}
          resolve={(code, type, product) => onBarcode(code, true, product)}
          searchPopup={searchPopup}
          store_id={store_id}
          withHistory={withHistory}
          activeRef={activeRef}
        />
      )}
      {!inSearch && (
        <Scan
          videoRef={videoRef}
          onBarcode={onBarcode}
          startSearch={() => {
            videoRef.current && videoRef.current.pause();
            setPauseScanner(true);
            setInSearch(true);
          }}
          onScannerError={() => {
            setScannerError(true);
            setInSearch(true);
          }}
          onCancel={() => {
            setNavigationState({
              query: null,
              code: null,
              inSearch: null,
              search: null,
              supplierNumber: null,
            });
            onClose();
          }}
          paused={pauseScanner || scanPopoverActive}
        />
      )}
      {toastVisible && (
        <SimpleToast
          text={"articleAlreadyInTestbuyString"}
          isOpen={toastVisible}
          onCloseRequest={() => {
            setToastVisible(false);
          }}
        />
      )}
      <ArticleError
        openArticleErrorModal={showArticleErrorModal}
        onClose={onCloseArticleErrorModal}
      />
    </React.Fragment>
  );
}

function SearchOnlyWithoutRouter({
  onClose,
  onSubmit,
  withHistory,
  displayRelatedTasks,
  start,
}) {
  const activeRef = React.useRef(true);
  const { setScannedItem } = useScanPopover();
  const { setNavigationState, push } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const store_id = useStoreId();
  const mounted = useMounted();

  const onBarcode = (code, type, product, supplierNumber) => {
    const [short_id] = parseProduct(code); // Defend against ARTART
    setNavigationState({ code, type, product, supplierNumber });
    setScannedItem({
      code,
      supplierNumber,
      product,
      store_id,
      displayRelatedTasks,
      withContinue: false,
      withActions: true,
      onSubmit,
      start: start,
      fromSearch: true,
      onAction: (url) => {
        setScannedItem(null);
        onClose();
        push(url, { product: { product: code, type } });
      },
      onRelated: () => {
        onClose();
        setScannedItem(null);
        push(`/tasks/related/${code}`);
      },
      onItemClick: () => {
        onClose();
        setScannedItem(null);
        push(`/product/${type + short_id}`);
      },
      close: () => {
        if (withHistory) {
          setNavigationState({ code: null, supplierNumber: null });
        }
      },
    });
  };

  React.useEffect(() => {
    if (navigationState && navigationState.code) {
      onBarcode(
        navigationState.code,
        navigationState.type,
        navigationState.product,
        navigationState.supplierNumber
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Search
        onClose={() => {
          mounted.current &&
            setNavigationState({
              query: null,
              code: null,
              inSearch: null,
              search: null,
              supplierNumber: null,
            });
          onClose();
        }}
        resolve={onBarcode}
        withHistory={withHistory}
        store_id={store_id}
        activeRef={activeRef}
      />
    </React.Fragment>
  );
}

export const SearchOnly = SearchOnlyWithoutRouter;

export default ScanAndSearchWithoutRouter;
