import React from "react";
import FixaTabs, {
  FixaTab,
  FixaTabPanel,
} from "@coworker/apprestructured/src/shared/wrappers/FixaTabs/FixaTabs";
import useFixaLocation from "@coworker/apprestructured/src/shared/wrappers/FixaLocation/useFixaLocation";

export function SkapaTabs({ tabs, active, panels }) {
  return (
    <FixaTabs
      style={{ paddingRight: "40px", paddingLeft: "30px" }}
      tabs={tabs}
      tabPanels={panels}
      activeTab={active}
    />
  );
}
export function SkapaTabPanel({ tabKey, children }) {
  return (
    <FixaTabPanel style={{ paddingTop: 3 }} key={tabKey} tabPanelId={tabKey}>
      {children}
    </FixaTabPanel>
  );
}

export function SkapaTab({
  tabKey,
  title,
  testId = "testId1",
  onTabChanged,
  setChecked,
}) {
  const { pathname } = useFixaLocation();
  return (
    <div
      style={{
        paddingRight: "15px",
      }}
    >
      <FixaTab
        text={title}
        onClick={() => {
          onTabChanged({
            area: pathname === "/testbuy/new" ? "" : "whole=whole",
            div: tabKey,
          });
          pathname !== "/testbuy/new" &&
            setChecked({ type: tabKey, item: "whole=whole" });
        }}
        tabPanelId={tabKey}
        data-testid={testId}
      />
    </div>
  );
}
