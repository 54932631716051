import React from "react";
import styled from "styled-components";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import TrashCanIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/TrashCanIconPath";
import { FixaQuantityStepper } from "@coworker/apprestructured/src/shared/wrappers/FixaQuantityStepper/FixaQuantityStepper";
import { useTranslation } from "@coworker/locales";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const ActionRowWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  margin-left: 68px;
`;

interface ArticleListItemActionRowProps {
  nbrArticles: number;
  onQuantityChange: (value: number) => void;
  selectArticleIdsMode: boolean;
  onDeleteArticle: () => void;
  doUseQuantityStepper?: boolean;
}

export const ArticleListItemActionRow: React.FC<
  ArticleListItemActionRowProps
> = ({
  nbrArticles,
  onQuantityChange,
  selectArticleIdsMode,
  onDeleteArticle,
  doUseQuantityStepper = true,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); // This stops the click event from propagating to parent elements
  };
  const { t } = useTranslation();

  if (selectArticleIdsMode) {
    return <></>;
  }

  return (
    <ActionRowWrapper
      onClick={(e) => {
        handleClick(e);
      }}
    >
      {doUseQuantityStepper ? (
        <FixaQuantityStepper
          small={true}
          ariaDescribedBy="articleQuantityStepper"
          ariaDescribedById="articleQuantityStepper"
          minVal={1}
          maxVal={99}
          defaultValue={nbrArticles}
          onQuantityChange={(value) => onQuantityChange(Number(value))}
        />
      ) : (
        <FixaButton
          size="small"
          text={t("editQuantityString")}
          onClick={() => onQuantityChange(nbrArticles)}
        />
      )}
      {!selectArticleIdsMode && (
        <>
          <ButtonWrapper>
            <FixaButton
              size="small"
              iconOnly={true}
              ssrIcon={TrashCanIconPath}
              onClick={onDeleteArticle}
            />
          </ButtonWrapper>
        </>
      )}
    </ActionRowWrapper>
  );
};
