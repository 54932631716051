import { Trans } from "@coworker/locales";
import React from "react";
import styled from "styled-components";
import { HFBList } from "./HFBList";
import { SearchInput } from "./SearchInput";
import { DivisionComponent } from "../UserFiltersRow/Divisions/DivisionComponent";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import useFlag from "../../../hooks/useFlag";
//@ts-ignore
import { FLAGS } from "@coworker/functions/src/enums/featureFlags";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import FixaSSRIcon from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/FixaSSRIcon";
import CrossIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/CrossIconPath";

const SearchContainer = styled.div`
  margin: 24px;
`;

interface PopupProps {
  onClose: () => void;
  onSubmit: (area: string) => void;
  initialArea?: string;
}

export function AreaSearchListPopup({ onClose, onSubmit }: PopupProps) {
  const [searchValue, setSearchValue] = React.useState<string>();
  const enableDivisionsArea = useFlag(FLAGS.DIVISIONS_IN_FILTER);
  const store_id = useStoreId();

  const onSelectedArea = React.useCallback(
    (area: any) => {
      onSubmit(area);
    },
    [onSubmit]
  );

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <FixaSSRIcon paths={CrossIconPath} />,
      onClick: onClose,
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: <Trans>areaString</Trans>,
        actions: appBarActions,
      }}
    >
      <div data-testid="number-of-articles-container">
        {enableDivisionsArea ? (
          <DivisionComponent storeId={store_id} setChecked={onSelectedArea} />
        ) : (
          <>
            {" "}
            <SearchContainer>
              <SearchInput value={searchValue} setValue={setSearchValue} />
            </SearchContainer>
            <HFBList filter={searchValue} onSelect={onSelectedArea} />
          </>
        )}
      </div>
    </FullScreenPopup>
  );
}
