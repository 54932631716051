import React, { useState } from "react";
import { FixaTextArea } from "@coworker/apprestructured/src/shared/wrappers/FixaTextArea/FixaTextArea";
import styled from "styled-components";
import { ReactComponent as CancelIcon } from "@coworker/components/src/assets/svg/icon-close-large.svg";
import { addArticlesBulk } from "../RoomSettings/services/articles.service";
import statusTypes from "@coworker/functions/src/enums/statusTypes.json";
import { Article } from "../RoomSettings/types/article";
import { useQueryClient } from "@tanstack/react-query";
import { useInputPopup } from "./index";
import { SimpleToast } from "@coworker/apprestructured/src/shared/simple/SimpleToast/SimpleToast";
import { mapArticleInfoToArticle } from "../RoomSettings/types/mappers";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { Trans, useTranslation } from "@coworker/locales";
import { correctArticleNumberLength } from "@coworker/functions/src/testbuy/acesAndKing/4A1K.helper";
import { fetchItemsInfo } from "@coworker/apprestructured/src/shared/hooks/item/useItemsInfo";
import { Item } from "@coworker/apprestructured/src/shared/types/item";
import { QueryKeys } from "../RoomSettings/hooks/queryKeys";
import { FocusedElement, SearchInput } from "./SearchInput";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";

const RoomSettingsPopupContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: auto;
`;
const RoomSettingsPopupTitle = styled.h1`
  display: flex;
  justify-content: flex-end;
`;
const HeadingWrapper = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: var(--grey900);
  padding: 25px;
  margin: 0 auto;
`;
const HeadingAndButtonWrapper = styled.div`
  display: inline-flex;
`;
const PaddedHorizontalContainer = styled.div`
  width: 312px;
  padding: 0 24px;
  z-index: 1;
`;

const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  margin-bottom: 90px;
`;
const CancelButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  margin-right: 7px;
  margin-left: 23px;
  margin-top: 15px;
  background: none;
`;

const CloseIcon = styled(CancelIcon)`
  color: var(--grey900);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AnimatedLoader = styled(LoaderIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 35%;
  z-index: 1;
`;

const InformationBox = styled.div`
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--grey700);
  padding: 13px 2px 2px 9px;
`;

const InformationBoxFirstLine = styled.div`
  font-size: 14px;
  letter-spacing: normal;
  color: var(--grey700);
  line-height: 1.5;
  margin-left: 15px;
`;

const InformationBoxSecondLine = styled.div`
  font-size: 14px;
  letter-spacing: normal;
  color: var(--grey700);
  margin-left: 15px;
  margin-bottom: 7px;
`;

const Seperator = styled.div`
  border-top: 2px solid var(--grey150);
  width: 140px;
`;

const SeparatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapperFixed = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 1px solid var(--grey150);
  align-items: center;
  z-index: 2;
`;

const ButtonWrapperInner = styled.div`
  padding: 24px;
  width: 100%;
`;

const ResultWrapText = styled.div`
  font-size: 12px;
  padding: 0 10px;
`;

interface AddMultipleArticlesMobileType {
  isOpen: boolean;
  roomId: string;
  groupId: string;
  countryId: string;
}

export function AddMultipleArticlesMobile(
  props: AddMultipleArticlesMobileType
) {
  const [inputValue, setInputValue] = useState<string>("");
  const queryClient = useQueryClient();
  const [toastVisible, setToastVisible] = useState(false);
  const [toastText, setToastText] = useState("");
  const [isAddingArticles, setIsAddingArticles] = useState(false);
  const [focusedElement, setFocusedElement] =
    useState<FocusedElement>("TextArea");
  const { t } = useTranslation();
  const { closePopup } = useInputPopup();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setInputValue(inputValue);

    const lines = e.target.value.split("\n");

    const cleanedLines = lines.map((line) => {
      const cleanedLine = line.replace(/[^0-9]/g, "").replace(/\t/g, " ");
      return cleanedLine.includes(" ") ? cleanedLine : cleanedLine.slice(0, 8);
    });

    const cleanedInput = cleanedLines.join("\n");
    if (cleanedInput.replace(/\D/g, "").length >= 8) {
      setInputValue(cleanedInput);
    }

    const addedArticles = cleanedInput.split("\n");
    addedArticles.reverse();
  };

  const handleSubmit = async () => {
    setIsAddingArticles(true);
    const articles =
      typeof inputValue === "string" ? inputValue.split("\n") : inputValue;
    const articleCountMap: { [id: string]: number } = {};
    const articleIdsWithQuantity: { articleId: string; quantity: number }[] =
      [];
    articles.forEach((article) => {
      const [id, quantity] = article.split(" ");
      if (id && articleCountMap[id]) {
        articleCountMap[id] += quantity ? parseInt(quantity, 10) : 1;
      } else if (id) {
        articleCountMap[id] = quantity ? parseInt(quantity, 10) : 1;
      }
    });

    Object.keys(articleCountMap).forEach((key) => {
      if (key !== "") {
        const quantity = articleCountMap[key];
        if (quantity !== undefined) {
          articleIdsWithQuantity.push({
            articleId: key,
            quantity,
          });
        }
      }
    });

    if (articleIdsWithQuantity.length > 0) {
      const articleIds = articleIdsWithQuantity.map(
        (article: { articleId: string; quantity: number }) =>
          correctArticleNumberLength(article.articleId.trim())
      );

      const itemNos = articleIds.join(",");
      const articleInfoList: Item[] = await queryClient.ensureQueryData(
        ["items", "info", itemNos],
        () => fetchItemsInfo(itemNos)
      );

      // Filter out articles which do not have article data to add them as unknown articles
      const articleInfosNotAdded = articleIds
        .filter(
          (articleId) =>
            !articleInfoList
              .map((articleData) => articleData.no)
              .filter((id) => id)
              .includes(articleId)
        )
        .map((articleId) => {
          return { no: articleId } as Item;
        });

      articleInfoList.push(...articleInfosNotAdded);

      if (articleInfoList.length > 0) {
        try {
          await addArticlesBulk(
            articleInfoList.map((articleData) => {
              const article = articleIdsWithQuantity.find(
                (articleIdAndQuantity) =>
                  articleIdAndQuantity.articleId === articleData.no
              );

              return {
                ...mapArticleInfoToArticle(articleData),
                active: statusTypes.ACTIVE,
                nbrArticles: article && article.quantity ? article.quantity : 1,
                roomId: props.roomId,
              } as Article;
            }),
            props.groupId
          );
          await Promise.all([
            queryClient.invalidateQueries([QueryKeys.ArticlesByRoom]),
            queryClient.invalidateQueries([QueryKeys.ArticlesByGroup]),
          ]);
        } catch (error) {
          alert(t("searchErrorString"));
        }
      } else {
        setToastText(t("noDataMessage"));
        setToastVisible(true);
      }
    }
    setIsAddingArticles(false);
    closePopup();
  };

  const onClose = () => {
    closePopup();
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    const key = event.key;
    // Check if Ctrl or Cmd is pressed along with 'v'
    const isPaste =
      (event.ctrlKey || event.metaKey) && key.toLowerCase() === "v";
    const isAllowedKey = [
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Enter",
      "Backspace",
    ].includes(key);
    // Leaving the Enter and Backspace keys to work as default and characters from a to z except 'v' to be prevented since we want to allow paste.
    if (key.match(/[^0-9/]/) && !isPaste && !isAllowedKey) {
      // Prevent default action if the key is anything but a number
      event.preventDefault();
    }
  }

  return (
    <>
      <RoomSettingsPopupContainer onClick={() => setFocusedElement("none")}>
        <HeadingAndButtonWrapper>
          <HeadingWrapper>
            <Trans>addArticlesString</Trans>
          </HeadingWrapper>
          <RoomSettingsPopupTitle>
            <CancelButton
              onClick={onClose}
              data-testid="closeAddmultipleArticles"
            >
              <CloseIcon />
            </CancelButton>
          </RoomSettingsPopupTitle>
        </HeadingAndButtonWrapper>
        <SearchInput
          hideSPR={true}
          withHistory={true}
          inputValue={inputValue}
          setInputValue={setInputValue}
          focusedElement={focusedElement}
          updateFocus={(isFocused) =>
            setFocusedElement(isFocused ? "Input" : "none")
          }
        />
        <PaddedHorizontalContainer>
          <SeparatorWrapper>
            <Seperator />
            <ResultWrapText>Or</ResultWrapText>
            <Seperator />
          </SeparatorWrapper>
          <InformationBox>
            <Trans>scanArticleRDTString</Trans>
          </InformationBox>
          <InformationBoxFirstLine>
            {"\u2022"} <Trans>oneArticlePerLineString</Trans>
          </InformationBoxFirstLine>
          <InformationBoxSecondLine>
            {"\u2022"} <Trans>scanMultipleTimesToIncreaseQuantityString</Trans>
          </InformationBoxSecondLine>
          <ModalItemWrapper>
            <FixaTextArea
              autoFocus={true}
              label={""}
              value={inputValue}
              style={{ height: "200px" }}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onClick={(event) => {
                setFocusedElement("TextArea");
                event.stopPropagation();
              }}
            />
          </ModalItemWrapper>
          {isAddingArticles && <AnimatedLoader />}
        </PaddedHorizontalContainer>
        <ButtonWrapperFixed>
          <ButtonWrapperInner>
            <FixaButton
              type="primary"
              key="addMultipleArticles"
              text={t("addArticlesString")}
              fluid={true}
              onClick={handleSubmit}
              iconPosition="trailing"
            />
          </ButtonWrapperInner>
        </ButtonWrapperFixed>
        {toastVisible && (
          <SimpleToast
            text={toastText}
            isOpen={toastVisible}
            onCloseRequest={() => {
              setToastVisible(false);
            }}
          />
        )}
      </RoomSettingsPopupContainer>
    </>
  );
}
