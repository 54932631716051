import React, { useRef } from "react";
import FixaButton from "@coworker/apprestructured/src/shared/wrappers/FixaButton/FixaButton";
import { createNote } from "../../../services/note.service";
import { useStoreId, useUserId } from "../../../core/auth/useLoggedInUser";
import PlusIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/PlusIconPath";
import { SimpleListContainer } from "../../RoomSettings/Molecules/SimpleListContainer";
import { Camera } from "../../RoomSettings/Atoms/Icons/Camera";
import { PaddedContainer } from "../../RoomSettings/styles/styles";
import { useUploadProofToTask } from "../../RoomSettings/hooks/useUploadProofToTask";
import { isChineseEnvironment } from "@coworker/reusable";
import { useTranslation } from "@coworker/locales";
import { updateTask } from "../../../services/task.service";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { CheckmarkCircle } from "../../RoomSettings/Atoms/Icons/CheckmarkCircle";

import useFlag, { FLAGS } from "../../../hooks/useFlag";
interface ProofOfCompletionProps {
  value: {
    proof_of_completion: boolean;
    isDone: boolean;
    setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
    state: string;
    id: string;
  };
}

export default function ProofOfCompletion({ value }: ProofOfCompletionProps) {
  const uid = useUserId();
  const storeId = useStoreId();
  const fileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const requireProofOfCompletion = useFlag(FLAGS.REQUIRE_PROOF_OF_COMPLETION);

  const handleButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const isChina = isChineseEnvironment();

  const addImageToTask = useUploadProofToTask();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let environment = "firebase";
    if (isChina === true) {
      environment = "alicloud";
    } else if (isChina === false) {
      environment = "gcp";
    }

    if (!event.target.files) {
      return;
    }

    const fileUploaded = event.target.files[0];
    if (!fileUploaded) return;
    const data = await addImageToTask.mutateAsync(fileUploaded);
    if (!data) return;

    const imageToAdd = {
      path: data.signed_url,
      folder: "notes",
      file_name: data.file_name,
      provider: environment,
      creator_id: uid,
      created_at: Date.now(),
    };
    if (!imageToAdd) return;
    createNote(
      value.id,
      { text: t("proofOfCompletionString"), creator_id: uid },
      [imageToAdd],
      storeId
    );

    updateTask(
      { proof_of_completion: true },
      value.id,
      taskTypeOptions.ROOM_SETTINGS
    );

    value.setIsDone(true);
  };

  return (
    <>
      {value.state === "ASSIGNED" && (
        <>
          <SimpleListContainer
            headline={t("proofOfCompletionString")}
            withAsterisk={requireProofOfCompletion}
            leftcontent={<Camera />}
          />
          <PaddedContainer $py={"8px"} $px={"16px"}>
            {(value.isDone || value.proof_of_completion) && (
              <div
                style={{
                  display: "flex",
                  color: "var(--green)",
                  marginLeft: "15%",
                  gap: "10px",
                }}
              >
                <CheckmarkCircle />
                <p>{t("proofOfCompletionSubmittedString")}</p>
              </div>
            )}
            <FixaButton
              loading={addImageToTask.isLoading}
              text={t("addPhotoString")}
              ssrIcon={PlusIconPath}
              onClick={handleButtonClick}
              style={{ marginLeft: "15%", marginTop: "15px" }}
            />
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={fileInput}
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </PaddedContainer>
        </>
      )}
    </>
  );
}
