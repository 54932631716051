import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import { Articles } from "../types/article";
import { Groups, PartialGroup } from "../types/groups";
import { ROOM_SETTINGS_SERVICE_URL } from "./service.helper";

const BASE_URL = ROOM_SETTINGS_SERVICE_URL + "/groups";

export const addGroup = async (group: PartialGroup) => {
  const token = await getTokenAsync(true);
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: makeHeaders(token),
    body: JSON.stringify(group),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return (await response.text()) as string;
};

export const getGroupById = async (groupId: string) => {
  const token = await getTokenAsync(true);
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(`${BASE_URL}/${groupId}`, {
    method: "GET",
    headers: makeHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  const group = (await response.json()) as PartialGroup;
  return group;
};

export const getGroupsByRoom = async (roomId: string) => {
  try {
    const token = await getTokenAsync(true);
    if (!roomId || !token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(`${BASE_URL}/rooms/${roomId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const groups = (await response.json()) as Groups;
    return groups;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [];
};

export const getArticlesByGroupId = async (
  groupId: string
): Promise<Articles> => {
  const token = await getTokenAsync(true);
  if (!token) {
    return [];
  }
  try {
    const response = await fetch(`${BASE_URL}/articles/${groupId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const articles = (await response.json()) as Articles;
    return articles.sort((a1, a2) => (a1.itemName > a2.itemName ? 1 : -1));
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [];
};

export const updateGroup = async (groupId: string, group: PartialGroup) => {
  const token = await getTokenAsync(true);
  if (!token || typeof token !== "string") {
    return;
  }
  const url = `${BASE_URL}/${groupId}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: makeHeaders(token),
    body: JSON.stringify(group),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};

export const deleteGroupById = async (groupId: string) => {
  const token = await getTokenAsync(true);
  if (!token || typeof token !== "string") {
    return;
  }
  const response = await fetch(`${BASE_URL}/${groupId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok.");
  }

  return;
};
